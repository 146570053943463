import React, { useState, useEffect, useCallback } from 'react';
import { Flex, Text, Spinner, useToast } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';

import ProductForm from '../../components/products/ProductForm';
import ProductImagesCard from '../../components/products/ProductImagesCard';
import Dropzone from '../../components/products/Dropzone';
import Card from '../../components/card/Card';

import { MdOutlineCloudUpload } from 'react-icons/md';

import {
  getProductDetails,
  updateProduct,
  handleImageUpload,
  handleDeleteImage,
  handleSetFeaturedImage,
  getProducts,
} from '../../api/product';
import { getCategories } from '../../api/category';
import { setTranslation } from '../../utils/translationUtils';
import {
  transformCategoriesForTreeSelect,
  addProductCounts,
} from '../../utils/CategoryUtils';

export default function ProductEdit() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const [productId, setProductId] = useState(null);
  const [productPrice, setProductPrice] = useState(0);
  const [currentLanguage] = useState('en');
  const [categoriesTree, setCategoriesTree] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [productCounts, setProductCounts] = useState({});

  const [isFeatured, setIsFeatured] = useState(false);
  const [newImages, setNewImages] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [soldQuantity, setSoldQuantity] = useState(0);
  const [loading, setLoading] = useState(true);

  const [languages] = useState([
    { code: 'en', name: 'English' },
    { code: 'et', name: 'Estonian' },
  ]);
  const [translations, setTranslations] = useState({
    en: { name: '', description: '', price: 0 },
    et: { name: '', description: '' },
  });
  const [quantity, setQuantity] = useState(0);

  const [categoriesData, setCategoriesData] = useState([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);

  const [productType, setProductType] = useState('physical');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [visibility, setVisibility] = useState('public');

  const [additionalFields, setAdditionalFields] = useState({
    sku: '',
    isbn: '',
    ean_upc: '',
    external_id: '',
    weight: '',
    weight_unit: '',
    external_url: '',
    tax_class: '',
    country_of_origin: '',
    brand_name: '',
    dimensions: '',
  });

  const fetchProduct = useCallback(async () => {
    try {
      const product = await getProductDetails(uuid);
      setProductId(product.id);
      setProductPrice(product.price);
      setIsFeatured(product.is_featured);
      setQuantity(product.quantity || 0);
      setSoldQuantity(product.sold_quantity || 0);
      setExistingImages(product.images || []);

      setTranslations({
        en: {
          name: product.translations?.en?.product_name || product.name,
          description: product.translations?.en?.product_description || '',
          price: product.price,
        },
        et: {
          name: product.translations?.et?.product_name || '',
          description: product.translations?.et?.product_description || '',
        },
      });

      setSelectedCategory(product.category_uuid || null);
      setProductType(product.product_type || 'physical');
      setVisibility(product.visibility || 'public');

      setAdditionalFields({
        sku: product.sku || '',
        isbn: product.isbn || '',
        ean_upc: product.ean_upc || '',
        external_id: product.external_id || '',
        weight: product.weight || '',
        weight_unit: product.weight_unit || '',
        external_url: product.external_url || '',
        tax_class: product.tax_class || '',
        country_of_origin: product.country_of_origin || '',
        brand_name: product.brand_name || '',
        dimensions: product.dimensions || '',
      });
    } catch (error) {
      console.error('Error fetching product:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch product details.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [uuid, toast]);

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  useEffect(() => {
    const fetchCategories = async () => {
      setIsLoadingCategories(true);
      try {
        const data = await getCategories();
        setCategoriesData(data);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
        toast({
          title: 'Error fetching categories.',
          description: 'Unable to load categories.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoadingCategories(false);
      }
    };
    fetchCategories();
  }, [toast]);

  useEffect(() => {
    if (categoriesData.length > 0) {
      let transformedCategories = transformCategoriesForTreeSelect(
        categoriesData,
        currentLanguage,
      );
      transformedCategories = addProductCounts(
        transformedCategories,
        productCounts,
      );
      setCategoriesTree(transformedCategories);
    }
  }, [categoriesData, currentLanguage, productCounts]);

  useEffect(() => {
    const fetchProductCounts = async () => {
      try {
        const response = await getProducts();
        const counts = {};
        response.forEach((product) => {
          if (product.category_uuid) {
            counts[product.category_uuid] =
              (counts[product.category_uuid] || 0) + 1;
          }
        });
        setProductCounts(counts);
      } catch (error) {
        console.error('Error fetching product counts:', error);
      }
    };
    fetchProductCounts();
  }, []);

  const handleCategoryChange = (selectedUuid) => {
    setSelectedCategory(selectedUuid);
  };

  const handleImageChange = (acceptedFiles) => {
    const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
    const maxSize = 5 * 1024 * 1024;
    const newValidFiles = [];

    acceptedFiles.forEach((file) => {
      if (!validTypes.includes(file.type)) {
        toast({
          title: 'Invalid file type.',
          description: 'Only PNG, JPG, and GIF files are allowed.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else if (file.size > maxSize) {
        toast({
          title: 'File too large.',
          description: 'Image size should be less than 5MB.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        newValidFiles.push(file);
      }
    });

    if (newValidFiles.length > 0) {
      setNewImages((prev) => {
        const updatedImages = [...prev, ...newValidFiles];
        setCurrentImageIndex(existingImages.length + updatedImages.length - 1);
        return updatedImages;
      });
    }
  };

  const handleSave = async () => {
    const translationsPayload = {
      en: {
        product_name: translations.en.name,
        product_description: translations.en.description,
      },
      et: {
        product_name: translations.et.name,
        product_description: translations.et.description,
      },
    };

    const updatedProduct = {
      price: Number(productPrice),
      is_featured: isFeatured,
      is_archived: false,
      status: 'active',
      translations: translationsPayload,
      quantity: quantity,
      category_uuid: selectedCategory || null,
      product_type: productType || null,
      visibility: visibility,
      sku: additionalFields.sku,
      isbn: additionalFields.isbn,
      ean_upc: additionalFields.ean_upc,
      external_id: additionalFields.external_id,
      weight: additionalFields.weight,
      weight_unit: additionalFields.weight_unit,
      external_url: additionalFields.external_url,
      tax_class: additionalFields.tax_class,
      country_of_origin: additionalFields.country_of_origin,
      brand_name: additionalFields.brand_name,
      dimensions: additionalFields.dimensions,
    };

    try {
      await updateProduct(uuid, updatedProduct);

      if (newImages.length > 0) {
        for (const img of newImages) {
          const uploadedImage = await handleImageUpload([img], uuid);
          setExistingImages((prev) => [...prev, uploadedImage]);
        }
        setNewImages([]);
      }

      for (const lang of languages.map((l) => l.code)) {
        if (translations[lang]) {
          if (translations[lang].name?.trim()) {
            await setTranslation(
              `product_name_${uuid}`,
              translations[lang].name,
              lang,
            );
          }
          if (translations[lang].description?.trim()) {
            await setTranslation(
              `product_description_${uuid}`,
              translations[lang].description,
              lang,
            );
          }
        }
      }

      toast({
        title: 'Product updated.',
        description: 'The product has been updated successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      navigate('/admin/products');
    } catch (error) {
      console.error('Error updating product:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while updating the product.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleQuantityChange = (value) => {
    const newQuantity = parseInt(value, 10) || 0;
    if (newQuantity >= soldQuantity) {
      setQuantity(newQuantity);
    } else {
      toast({
        title: 'Invalid Quantity',
        description: 'Quantity cannot be less than the sold quantity.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleTranslationChange = (lang, field, value) => {
    setTranslations((prev) => ({
      ...prev,
      [lang]: { ...prev[lang], [field]: value },
    }));
  };

  const handleDeleteImageWrapper = (imageUuid) => {
    handleDeleteImage(uuid, imageUuid)
      .then(() => {
        setExistingImages((prev) =>
          prev.filter((image) => image.uuid !== imageUuid),
        );
        toast({
          title: 'Image Deleted',
          description: 'The image has been deleted successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error('Error deleting image:', error);
        toast({
          title: 'Error',
          description: 'Failed to delete image',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleSetFeaturedImageWrapper = (imageUuid) => {
    handleSetFeaturedImage(uuid, imageUuid)
      .then(() => {
        setExistingImages((prevImages) => {
          const updatedImages = prevImages.map((img) => ({
            ...img,
            is_featured: img.uuid === imageUuid,
          }));
          return updatedImages;
        });
        setIsFeatured(true);
        toast({
          title: 'Featured Image Set',
          description: 'The image has been set as featured.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error('Error setting featured image:', error);
        toast({
          title: 'Error',
          description: 'Failed to set featured image',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const sortImages = (images) => {
    return images.sort((a, b) => (b.is_featured ? 1 : -1));
  };

  const combinedImages = sortImages([
    ...existingImages,
    ...newImages.map((file) => ({
      uuid: file.name + '-' + file.lastModified,
      image_url: URL.createObjectURL(file),
      is_featured: false,
    })),
  ]);

  const handlePrevImage = () => {
    if (combinedImages.length === 0) return;
    setCurrentImageIndex(
      (prevIndex) =>
        (prevIndex - 1 + combinedImages.length) % combinedImages.length,
    );
  };

  const handleNextImage = () => {
    if (combinedImages.length === 0) return;
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % combinedImages.length,
    );
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <ProductForm
      languages={languages}
      translations={translations}
      handleTranslationChange={handleTranslationChange}
      isFeatured={isFeatured}
      setIsFeatured={setIsFeatured}
      quantity={quantity}
      soldQuantity={soldQuantity}
      handleQuantityChange={handleQuantityChange}
      selectedCategory={selectedCategory}
      categoriesTree={categoriesTree}
      isLoadingCategories={isLoadingCategories}
      handleCategoryChange={handleCategoryChange}
      productType={productType}
      setProductType={setProductType}
      visibility={visibility}
      setVisibility={setVisibility}
      productPrice={productPrice}
      setProductPrice={setProductPrice}
      saveAction={handleSave}
      leftChildren={
        <ProductImagesCard
          combinedImages={combinedImages}
          currentImageIndex={currentImageIndex}
          onPrev={handlePrevImage}
          onNext={handleNextImage}
          onDelete={handleDeleteImageWrapper}
          onSetFeatured={handleSetFeaturedImageWrapper}
        />
      }
      additionalFields={additionalFields}
      setAdditionalFields={setAdditionalFields}
    >
      <Card p="30px" mb="20px">
        <Text fontSize="xl" fontWeight="bold" mb="10px">
          Product Images
        </Text>
        <Dropzone onDrop={handleImageChange}>
          <Flex direction="column" align="center" justify="center" h="200px">
            <MdOutlineCloudUpload size="50px" style={{ marginBottom: '8px' }} />
            <Text fontSize="lg" fontWeight="bold">
              Drag and drop images here, or click to select files
            </Text>
            <Text fontSize="sm" color="gray.500">
              PNG, JPG, and GIF files are allowed
            </Text>
          </Flex>
        </Dropzone>
      </Card>
    </ProductForm>
  );
}
