import apiClient from './axios';

export const getProductDiscounts = async (filters = {}) => {
  try {
    const response = await apiClient.get('/product-discounts', {
      params: filters,
    });

    if (Array.isArray(response.data)) {
      return {
        data: response.data,
        total: response.data.length,
        page: 1,
        limit: response.data.length,
      };
    } else {
      return response.data;
    }
  } catch (error) {
    console.error('Error fetching product discounts:', error);
    throw error;
  }
};

export const getDiscount = async (uuid) => {
  try {
    const response = await apiClient.get(`/product-discounts/${uuid}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) {
        throw new Error('Discount not found.');
      }
    }
    console.error('Error fetching discount:', error);
    throw error;
  }
};

export const createProductDiscount = async (data) => {
  try {
    const payload = cleanPayload({
      product_uuid: data.product_uuid,
      product_uuids: data.product_uuids,
      category_uuid: data.category_uuid,
      start_time: data.start_time,
      end_time: data.end_time,
      is_active: data.is_active,
      discount_price: data.discount_price,
      discount_percentage: data.discount_percentage,
      loyalty_program_uuid: data.loyalty_program_uuid,
      loyalty_level_uuid: data.loyalty_level_uuid,
      discount_type: data.discount_type,
    });

    const response = await apiClient.post('/product-discounts', payload);
    return response.data;
  } catch (error) {
    console.error('Error creating product discount:', error);
    throw error;
  }
};

const cleanPayload = (obj) => {
  const allowedNullFields = ['start_time', 'end_time'];
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => {
      if (allowedNullFields.includes(key)) {
        return true;
      }
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== null && value !== undefined;
    }),
  );
};

export const updateProductDiscount = async (discountUuid, data) => {
  try {
    const cleanedData = cleanPayload({
      product_uuid: data.product_uuid,
      product_uuids:
        data.product_uuids && data.product_uuids.length > 0
          ? data.product_uuids.filter((uuid) => uuid && uuid.trim() !== '')
          : undefined,
      category_uuid: data.category_uuid,
      start_time: data.start_time,
      end_time: data.end_time,
      is_active: data.is_active,
      discount_price: data.discount_price,
      discount_percentage: data.discount_percentage,
      loyalty_program_uuid: data.loyalty_program_uuid,
      loyalty_level_uuid: data.loyalty_level_uuid,
      discount_type: data.discount_type,
    });

    const response = await apiClient.put(
      `/product-discounts/${discountUuid}`,
      cleanedData,
    );
    return response.data;
  } catch (error) {
    console.error('Error updating product discount:', error);
    throw error;
  }
};

export const deleteProductDiscount = async (discountUuid) => {
  try {
    await apiClient.delete(`/product-discounts/${discountUuid}`);
  } catch (error) {
    console.error('Error deleting product discount:', error);
    throw error;
  }
};

export const bulkDeleteDiscounts = async (uuids) => {
  try {
    const response = await apiClient.delete('/product-discounts', {
      data: { uuids },
    });
    return response.data;
  } catch (error) {
    console.error('Error bulk deleting discounts:', error);
    throw error;
  }
};
