import React from 'react';
import { Text } from '@chakra-ui/react';

export const HighlightedText = ({ text = '', highlight = '', ...props }) => {
  if (!highlight) return <Text {...props}>{text}</Text>;

  // Create a regex to find all case-insensitive occurrences of the highlight term
  const regex = new RegExp(`(${highlight})`, 'gi');
  const parts = text.split(regex);

  return (
    <Text {...props}>
      {parts.map((part, i) =>
        regex.test(part) ? (
          <mark key={i} style={{ backgroundColor: 'yellow' }}>
            {part}
          </mark>
        ) : (
          <span key={i}>{part}</span>
        ),
      )}
    </Text>
  );
};
