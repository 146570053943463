import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Grid,
  Text,
  Icon,
  Select,
  Spinner,
  useColorModeValue,
  FormControl,
} from '@chakra-ui/react';
import LineChart from 'components/charts/LineChart';
import Card from 'components/card/Card';
import { getSales } from 'api/dashboard';
import { RiArrowUpSFill } from 'react-icons/ri';
import {
  format,
  parseISO,
  startOfWeek,
  addWeeks,
  isValid,
  compareAsc,
} from 'date-fns';

export default function SalesWidget(props) {
  const { startDate, endDate, groupBy, ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  // State variables
  const [salesData, setSalesData] = useState([]);
  const [totalSales, setTotalSales] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  // Filter states
  const [productType, setProductType] = useState('all');

  // Fetch sales data
  useEffect(() => {
    const fetchSales = async () => {
      setIsLoading(true);
      try {
        const params = {
          group_by: groupBy,
          product_type: productType,
        };
        if (startDate) params.start_date = startDate;
        if (endDate) params.end_date = endDate;

        const data = await getSales(params);
        setSalesData(data.sales_data);
        setTotalSales(data.total_sales);
        setTotalOrders(data.total_orders);
      } catch (error) {
        console.error('Error fetching sales data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSales();
  }, [groupBy, productType, startDate, endDate]);

  const sortedRevenueData = (salesData || []).sort((a, b) =>
    compareAsc(parseISO(a.period), parseISO(b.period)),
  );

  const chartData = [
    {
      name: 'Sales',
      data: sortedRevenueData.map((item) => item.sales),
    },
  ];

  const chartCategories = sortedRevenueData.map((item) => {
    let date = parseISO(item.period);

    if (groupBy === 'week') {
      // Parse week number and year
      const year = parseInt(item.period.slice(0, 4), 10);
      const week = parseInt(item.period.slice(4), 10);
      date = startOfWeek(addWeeks(new Date(year, 0, 1), week - 1), {
        weekStartsOn: 1,
      });
    } else {
      date = parseISO(item.period);
    }

    if (!isValid(date)) {
      console.error(`Invalid date value: ${item.period}`);
      return item.period; // Return the original value if the date is invalid
    }
    if (groupBy === 'day') {
      return format(date, 'dd/MM');
    } else if (groupBy === 'week') {
      return `W${format(date, 'w yyyy')}`;
    } else if (groupBy === 'month') {
      return format(date, 'MMM');
    } else if (groupBy === 'year') {
      return format(date, 'yyyy');
    } else {
      return item.period;
    }
  });

  if (chartCategories.length !== chartData[0].data.length) {
    console.error('Mismatch between chart categories and data points.');
  }

  const chartOptions = {
    chart: {
      toolbar: { show: false },
    },
    xaxis: {
      categories: chartCategories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    yaxis: {
      show: false,
    },
    stroke: {
      curve: 'smooth',
      width: 5,
    },
    colors: ['#3182CE'],
  };
  return (
    <Card
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100%"
      mb={{ base: '20px', lg: '0px' }}
      {...rest}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Flex
            justify="space-between"
            px="20px"
            pt="5px"
            w="100%"
            flexWrap="wrap"
          >
            <Flex align="center" mb="10px">
              <Flex flexDirection="column" me="20px">
                <Text
                  color={textColor}
                  fontSize="34px"
                  fontWeight="700"
                  lineHeight="100%"
                >
                  €{totalSales.toFixed(2)}
                </Text>
                <Text
                  color="secondaryGray.600"
                  fontSize="sm"
                  fontWeight="500"
                  mt="4px"
                >
                  Total Sales
                </Text>
              </Flex>
              <Flex align="center">
                <Icon as={RiArrowUpSFill} color="green.500" me="2px" />
                <Text color="green.500" fontSize="sm" fontWeight="700">
                  {totalOrders} Orders
                </Text>
              </Flex>
            </Flex>
            {/* Filter controls */}
            <Box mb="10px" ml="auto">
              <FormControl>
                <Select
                  fontSize="sm"
                  variant="subtle"
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                >
                  <option value="all">All Products</option>
                  <option value="loyalty">Loyalty Products</option>
                  <option value="regular">Regular Products</option>
                </Select>
              </FormControl>
            </Box>
          </Flex>
          <Box minH="200px" mt="auto" w="100%">
            <LineChart chartData={chartData} chartOptions={chartOptions} />
          </Box>
        </>
      )}
    </Card>
  );
}
