import { createColumnHelper } from '@tanstack/react-table';
import { Text, Badge, Flex, Tooltip, Box } from '@chakra-ui/react';
import {
  MdEdit,
  MdContentCopy,
  MdExpandMore,
  MdChevronRight,
} from 'react-icons/md';
import useBrandColor from '../../hooks/useBrandColor';
import { HighlightedText } from '../../components/discounts/HighlightedText';

const columnHelper = createColumnHelper();

const useDiscountColumns = (
  textColor,
  navigate,
  handleDuplicate,
  loyaltyPrograms,
  loyaltyLevels,
  products,
  isSmallScreen,
) => {
  const brandColor = useBrandColor();

  const allColumns = [
    columnHelper.display({
      id: 'select',
      header: ({ table }) => (
        <Flex align="center">
          <input
            type="checkbox"
            {...{
              checked: table.getIsAllRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
          <Text ml={3}>All</Text>
        </Flex>
      ),
      cell: ({ row }) => (
        <input
          type="checkbox"
          {...{
            checked: row.getIsSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
      enableSorting: false,
      enableHiding: false,
    }),
    columnHelper.display({
      id: 'expander',
      header: () => null,
      cell: ({ row }) => {
        const { discount_type } = row.original;
        if (discount_type === 'all_products') {
          return null;
        }
        const canExpand = row.getCanExpand();
        return canExpand ? (
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Text
              as="span"
              cursor="pointer"
              onClick={row.getToggleExpandedHandler()}
              fontSize="24px"
            >
              {row.getIsExpanded() ? <MdExpandMore /> : <MdChevronRight />}
            </Text>
          </Box>
        ) : null;
      },
      enableSorting: false,
      enableHiding: false,
    }),

    columnHelper.accessor('product_name', {
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          Product Name
        </Text>
      ),
      cell: (info) => {
        const { discount_type } = info.row.original;
        let productName = 'Multiple Products';

        if (discount_type === 'all_products') {
          productName = 'All Products';
        } else if (info.getValue()) {
          productName = info.getValue();
        } else if (discount_type === 'category') {
          productName = 'Categories';
        }

        const globalFilter = info.table.options.state.globalFilter || '';

        return (
          <HighlightedText
            text={productName}
            highlight={globalFilter}
            fontWeight={
              info.row.original.parent_id === null ||
              info.row.original.parent_id === 0
                ? 'bold'
                : 'normal'
            }
          />
        );
      },
    }),

    columnHelper.accessor('sale_price', {
      id: 'sale_price',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          Sale Price
        </Text>
      ),
      cell: (info) => {
        const salePrice = info.row.original.sale_price;
        if (salePrice === null) {
          return <Text>-</Text>;
        }
        if (salePrice === 'Multiple Products') {
          return <Text>Multiple Sale Prices</Text>;
        }
        return <Text>€{Number(salePrice).toFixed(2)}</Text>;
      },
    }),

    columnHelper.accessor(
      (row) => {
        if (row.discount_percentage != null) return row.discount_percentage;
        if (row.discount_price != null) return row.discount_price;
        return null;
      },
      {
        id: 'discount_value',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
            Discount
          </Text>
        ),
        cell: (info) => {
          const row = info.row.original;
          const value = info.getValue();
          if (value === null) {
            return <Text>-</Text>;
          }
          if (row.discount_percentage !== null) {
            return <Text>{row.discount_percentage}%</Text>;
          } else {
            return <Text>€{Number(row.discount_price).toFixed(2)}</Text>;
          }
        },
      },
    ),

    columnHelper.accessor('discount_type', {
      id: 'to_whom',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          To Whom
        </Text>
      ),
      cell: (info) => {
        const { loyalty_program_uuid, loyalty_level_uuid } = info.row.original;
        let displayText = '';
        if (!loyalty_program_uuid && !loyalty_level_uuid) {
          displayText = 'Everyone';
        } else if (loyalty_program_uuid && !loyalty_level_uuid) {
          const loyaltyProgram = loyaltyPrograms?.find(
            (lp) => lp.uuid === loyalty_program_uuid,
          );
          const loyaltyProgramName = loyaltyProgram
            ? loyaltyProgram.name
            : 'this loyalty program';
          displayText = `Users of ${loyaltyProgramName}`;
        } else if (loyalty_level_uuid) {
          const loyaltyLevel = loyaltyLevels?.find(
            (ll) => ll.uuid === loyalty_level_uuid,
          );
          const loyaltyLevelName = loyaltyLevel
            ? loyaltyLevel.name
            : 'this loyalty level';
          displayText = `Users of ${loyaltyLevelName}`;
        }
        return <Text>{displayText}</Text>;
      },
    }),

    columnHelper.accessor('start_time', {
      id: 'period',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          Period
        </Text>
      ),
      cell: (info) => {
        const startTime = info.getValue();
        const endTime = info.row.original.end_time;

        const start = startTime
          ? new Date(startTime).toLocaleDateString()
          : null;
        const end = endTime ? new Date(endTime).toLocaleDateString() : null;

        if (!start && !end) {
          return <Text>Always</Text>;
        } else if (start && !end) {
          return <Text>{start} - Always</Text>;
        } else if (!start && end) {
          return <Text>Now - {end}</Text>;
        } else {
          return (
            <Text>
              {start} - {end}
            </Text>
          );
        }
      },
    }),

    columnHelper.accessor('discount_type', {
      id: 'which_products',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          Which Products
        </Text>
      ),
      cell: (info) => {
        const { discount_type, children, product_name } = info.row.original;

        if (discount_type === 'all_products') {
          return <Text>All Products</Text>;
        }

        let productNames = [];

        if (info.row.getIsExpanded() && info.row.subRows.length > 0) {
          productNames = info.row.subRows.map(
            (subRow) => subRow.original.product_name,
          );
        } else if (children && children.length > 0) {
          productNames = children.map((child) => child.product_name);
        } else if (product_name) {
          productNames = [product_name];
        }

        let displayText = '';
        if (productNames.length > 0) {
          displayText = productNames[0];
          if (productNames.length > 1) {
            displayText += `, +${productNames.length - 1} more`;
          }
        } else {
          displayText = 'No Products';
        }

        return (
          <Tooltip label={productNames.join(', ')}>
            <Text>{displayText}</Text>
          </Tooltip>
        );
      },
    }),

    columnHelper.accessor('is_active', {
      id: 'active_status',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          Active
        </Text>
      ),
      cell: (info) => (
        <Badge colorScheme={info.getValue() ? 'green' : 'red'}>
          {info.getValue() ? 'Active' : 'Inactive'}
        </Badge>
      ),
    }),

    columnHelper.display({
      id: 'actions',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          Actions
        </Text>
      ),
      cell: (info) => {
        const isParent = !info.row.original.parent_id;
        if (!isParent) {
          return null;
        }
        return (
          <Flex>
            <Tooltip label="Edit" shouldWrapChildren>
              <Text
                w="16px"
                h="16px"
                as={MdEdit}
                cursor="pointer"
                color={brandColor}
                mr="8px"
                onClick={() =>
                  navigate(`/admin/discounts/edit/${info.row.original.uuid}`)
                }
              />
            </Tooltip>
            <Tooltip label="Duplicate" shouldWrapChildren>
              <Text
                w="16px"
                h="16px"
                as={MdContentCopy}
                cursor="pointer"
                color={brandColor}
                mr="8px"
                onClick={() => handleDuplicate(info.row.original.uuid)}
              />
            </Tooltip>
          </Flex>
        );
      },
    }),
  ];

  let filteredColumns = allColumns;
  if (isSmallScreen) {
    const columnsToHide = ['which_products', 'active_status'];
    filteredColumns = allColumns.filter(
      (col) => !columnsToHide.includes(col.id),
    );
  }

  return filteredColumns;
};

export default useDiscountColumns;
