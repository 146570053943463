// src/components/discounts/DiscountFilters.jsx

import React, { useEffect } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  Button,
  Select,
  Checkbox,
  VStack,
  HStack,
  IconButton,
  Icon,
} from '@chakra-ui/react';
import { ChevronDownIcon, RepeatIcon } from '@chakra-ui/icons';
import { FiFilter } from 'react-icons/fi';

const DiscountFilters = ({
  productUUID,
  setProductUUID,
  loyaltyProgramUUID,
  setLoyaltyProgramUUID,
  loyaltyLevelUUID,
  setLoyaltyLevelUUID,
  isActive,
  setIsActive,
  resetFilters,
  filteredProducts,
  filteredLoyaltyPrograms,
  filteredLoyaltyLevels,
  setPagination,
}) => {
  // Reset loyalty level when loyalty program changes
  useEffect(() => {
    setLoyaltyLevelUUID('');
  }, [loyaltyProgramUUID, setLoyaltyLevelUUID]);

  return (
    <Menu closeOnSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            leftIcon={<Icon as={FiFilter} />}
            variant="outline"
            ml={4}
          ></MenuButton>
          <MenuList p={4} minW="300px">
            <VStack align="start" spacing={4}>
              {/* Product Filter */}
              <Select
                value={productUUID}
                onChange={(e) => {
                  setProductUUID(e.target.value);
                  setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                }}
                placeholder="All Products"
              >
                {filteredProducts && filteredProducts.length > 0 ? (
                  filteredProducts.map((product) => (
                    <option key={product.uuid} value={product.uuid}>
                      {product.name}
                    </option>
                  ))
                ) : (
                  <option disabled>No Products Available</option>
                )}
              </Select>

              {/* Loyalty Program Filter */}
              <Select
                value={loyaltyProgramUUID}
                onChange={(e) => {
                  setLoyaltyProgramUUID(e.target.value);
                  setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                }}
                placeholder="All Loyalty Programs"
              >
                {filteredLoyaltyPrograms.length > 0 ? (
                  filteredLoyaltyPrograms.map((program) => (
                    <option key={program.uuid} value={program.uuid}>
                      {program.name} {/* This should now display correctly */}
                    </option>
                  ))
                ) : (
                  <option disabled>No Loyalty Programs with Discounts</option>
                )}
              </Select>

              {/* Loyalty Level Filter */}
              <Select
                value={loyaltyLevelUUID}
                onChange={(e) => {
                  setLoyaltyLevelUUID(e.target.value);
                  setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                }}
                placeholder="All Loyalty Levels"
                isDisabled={!loyaltyProgramUUID} // Disable if no loyalty program selected
              >
                {filteredLoyaltyLevels.length > 0 ? (
                  filteredLoyaltyLevels.map((level) => (
                    <option key={level.uuid} value={level.uuid}>
                      {level.name}
                    </option>
                  ))
                ) : (
                  <option disabled>
                    {loyaltyProgramUUID
                      ? 'No Loyalty Levels for Selected Program'
                      : 'Select a Loyalty Program first'}
                  </option>
                )}
              </Select>

              {/* Active Status Filter */}
              <Checkbox
                isChecked={isActive}
                onChange={(e) => {
                  setIsActive(e.target.checked);
                  setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                }}
              >
                Active
              </Checkbox>

              {/* Reset Filters Button */}
              <HStack w="100%" justify="flex-end">
                <IconButton
                  aria-label="Reset Filters"
                  icon={<RepeatIcon />}
                  size="sm"
                  variant="outline"
                  onClick={resetFilters}
                />
              </HStack>
            </VStack>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default DiscountFilters;
