// src/contexts/CategoriesContext.js

import React, { createContext, useState, useEffect, useContext } from 'react';
import { getCategories } from '../api/category';
import { transformCategoriesForTreeSelect } from '../utils/CategoryUtils';
import { AuthContext } from './AuthContext'; // Import AuthContext

export const CategoriesContext = createContext();

export const CategoriesProvider = ({ children }) => {
  const { isAuthenticated, authToken } = useContext(AuthContext); // Destructure necessary auth state
  const [categories, setCategories] = useState([]);
  const [transformedCategories, setTransformedCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [errorCategories, setErrorCategories] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      if (!isAuthenticated) {
        setLoadingCategories(false);
        return;
      }

      try {
        const categoriesData = await getCategories(authToken); // Pass authToken if needed
        setCategories(categoriesData);
        const treeCategories = transformCategoriesForTreeSelect(
          categoriesData,
          'en',
        );
        setTransformedCategories(treeCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setErrorCategories('Failed to fetch categories.');
      } finally {
        setLoadingCategories(false);
      }
    };

    fetchCategories();
  }, [isAuthenticated, authToken]); // Depend on authentication state

  return (
    <CategoriesContext.Provider
      value={{
        categories,
        transformedCategories,
        loadingCategories,
        errorCategories,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};
