// api/product.js

import apiClient from './axios';
import { getLoyaltyPrograms, getLoyaltyLevels } from './loyalty';

export const getProducts = async (filters = {}) => {
  try {
    const response = await apiClient.get('/products', {
      params: filters,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};

export const getProductDetails = async (productUuid) => {
  try {
    console.log(`Fetching details for product UUID: ${productUuid}`);
    const response = await apiClient.get(`/products/${productUuid}`);
    console.log('Product details fetched successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching product details:', error);
    throw error;
  }
};

export const createProduct = async (data) => {
  try {
    const response = await apiClient.post('/products', data);
    console.log('Create Product Response:', response); // Should include `uuid`

    return response.data;
  } catch (error) {
    console.error('Error creating product:', error);
    throw error;
  }
};

export const updateProduct = async (productUuid, data) => {
  try {
    const response = await apiClient.put(`/products/${productUuid}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating product:', error);
    throw error;
  }
};

export const archiveProduct = async (productUuid) => {
  try {
    const response = await apiClient.delete(`/products/${productUuid}`);
    return response; // Return the full response for proper handling
  } catch (error) {
    console.error('Error archiving product:', error);
    throw error;
  }
};

export const uploadProductImage = async (productUuid, image) => {
  try {
    if (!image) {
      throw new Error('No image provided for upload.');
    }

    const formData = new FormData();
    formData.append('file', image);

    const response = await apiClient.post(
      `/products/${productUuid}/images`,
      formData,
    );
    return response.data;
  } catch (error) {
    console.error('Error uploading product image:', error);
    throw error;
  }
};

// New functions added from loyaltyHandlers.js
export const handleImageUpload = async (files, uuid) => {
  const file = files[0];
  const formData = new FormData();
  formData.append('file', file);

  if (!uuid) {
    throw new Error(
      'No UUID provided. Please save the product before uploading images.',
    );
  }

  try {
    const response = await apiClient.post(`/products/${uuid}/images`, formData);
    return response.data;
  } catch (error) {
    console.error('Error uploading product image:', error);
    throw error;
  }
};

export const handleSetFeaturedImage = async (productUuid, imageUuid) => {
  try {
    const response = await apiClient.put(
      `/products/${productUuid}/images/${imageUuid}/featured`,
      {},
    );
    return response.data;
  } catch (error) {
    console.error('Error setting featured image:', error);
    throw error;
  }
};

export const handleDeleteImage = async (productUuid, imageUuid) => {
  try {
    const response = await apiClient.delete(
      `/products/${productUuid}/images/${imageUuid}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting product image:', error);
    throw error;
  }
};

// Exporting loyalty functions
export { getLoyaltyPrograms, getLoyaltyLevels };
