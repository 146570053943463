import { useState, useEffect } from 'react';
import { getProductDiscounts } from '../api/discount';

const useDiscounts = (filters) => {
  const [discounts, setDiscounts] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDiscounts = async () => {
      setLoading(true);
      setError(null);
      try {
        console.log('Fetching discounts with filters:', filters);

        // Extract and remove product_uuid from filters for API call
        const { product_uuid, ...restFilters } = filters;
        const shouldFilterByProduct = !!product_uuid;

        // Use adjusted filters without product_uuid
        const response = await getProductDiscounts(restFilters);
        console.log('API Response:', response);

        if (response.data && typeof response.total === 'number') {
          // Filter out child discounts from API response
          let parentsData = response.data.filter(
            (discount) =>
              discount.parent_id === 0 || discount.parent_id === null,
          );

          // Transform 'child_discounts' to 'children' and exclude 'child_discounts'
          let transformedDiscounts = parentsData.map((parent) => {
            const { child_discounts, ...rest } = parent;
            return {
              ...rest,
              children: child_discounts || [],
            };
          });

          // If filtering by product_uuid, filter discounts whose children include that product
          if (shouldFilterByProduct) {
            transformedDiscounts = transformedDiscounts.filter((discount) =>
              discount.children.some(
                (child) => child.product_uuid === product_uuid,
              ),
            );
          }

          setDiscounts(transformedDiscounts);
          // Update total count based on filtered results
          setTotal(transformedDiscounts.length);
        } else if (Array.isArray(response)) {
          setDiscounts(response);
          setTotal(response.length);
        } else {
          console.error('Unexpected API response structure:', response);
          setError('Unexpected API response structure');
        }
      } catch (err) {
        console.error('Error fetching discounts:', err);
        setError(err.message || 'Failed to fetch discounts');
      } finally {
        setLoading(false);
      }
    };

    fetchDiscounts();
  }, [filters]);

  return { discounts, total, loading, error };
};

export default useDiscounts;
