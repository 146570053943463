// src/pages/discounts/DiscountOverview.jsx
import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Flex,
  Card,
  Spinner,
  Text,
  Button,
  useColorModeValue,
  Alert,
  AlertIcon,
  useToast,
  Box,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useMediaQuery,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { useNavigate, Outlet } from 'react-router-dom';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getExpandedRowModel,
} from '@tanstack/react-table';
import { useDebounce } from 'use-debounce';
import Pagination from '../../components/pagination';
import DiscountFilters from '../../components/discounts/DiscountFilters';
import DiscountTable from '../../components/discounts/DiscountTable';
import useDiscountColumns from '../../components/discounts/discountColumns';

import useProducts from '../../hooks/useProducts';
import useLoyaltyPrograms from '../../hooks/useLoyaltyPrograms';
import useLoyaltyLevels from '../../hooks/useLoyaltyLevels';
import useDiscounts from '../../hooks/useDiscounts';
import {
  createProductDiscount,
  getDiscount,
  bulkDeleteDiscounts,
} from '../../api/discount';

import DiscountTemplates from './data/discountTemplates';

export default function DiscountOverview() {
  const navigate = useNavigate();
  const toast = useToast();
  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');
  const [rowSelection, setRowSelection] = useState({});
  const [isSmallScreen] = useMediaQuery('(max-width: 768px)');

  const {
    isOpen: isBulkDeleteOpen,
    onOpen: onBulkDeleteOpen,
    onClose: onBulkDeleteClose,
  } = useDisclosure();
  const cancelRef = useRef();

  const [productUUID, setProductUUID] = useState(() => {
    const stored = localStorage.getItem('discountOverview_productUUID');
    return stored || '';
  });
  const [loyaltyProgramUUID, setLoyaltyProgramUUID] = useState(() => {
    const stored = localStorage.getItem('discountOverview_loyaltyProgramUUID');
    return stored || '';
  });
  const [loyaltyLevelUUID, setLoyaltyLevelUUID] = useState(() => {
    const stored = localStorage.getItem('discountOverview_loyaltyLevelUUID');
    return stored || '';
  });
  const [isActive, setIsActive] = useState(() => {
    const stored = localStorage.getItem('discountOverview_isActive');
    const parsed = stored !== null ? JSON.parse(stored) : true;
    return parsed;
  });

  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 30 });

  const [debouncedProductUUID] = useDebounce(productUUID, 300);
  const [debouncedLoyaltyProgramUUID] = useDebounce(loyaltyProgramUUID, 300);
  const [debouncedLoyaltyLevelUUID] = useDebounce(loyaltyLevelUUID, 300);
  const [debouncedIsActive] = useDebounce(isActive, 300);
  const [refreshKey, setRefreshKey] = useState(0);

  const {
    products,
    loading: productsLoading,
    error: productsError,
  } = useProducts();
  const {
    loyaltyPrograms,
    loading: loyaltyProgramsLoading,
    error: loyaltyProgramsError,
  } = useLoyaltyPrograms();
  const {
    loyaltyLevels,
    loading: loyaltyLevelsLoading,
    error: loyaltyLevelsError,
  } = useLoyaltyLevels();

  const filters = useMemo(
    () => ({
      product_uuid: debouncedProductUUID || undefined,
      loyalty_program_uuid: debouncedLoyaltyProgramUUID || undefined,
      loyalty_level_uuid: debouncedLoyaltyLevelUUID || undefined,
      is_active: debouncedIsActive,
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      refreshKey,
    }),
    [
      debouncedProductUUID,
      debouncedLoyaltyProgramUUID,
      debouncedLoyaltyLevelUUID,
      debouncedIsActive,
      pagination.pageIndex,
      pagination.pageSize,
      refreshKey,
    ],
  );

  const {
    discounts = [],
    total = 0,
    loading: discountsLoading,
    error: discountsError,
  } = useDiscounts(filters);

  const enhancedDiscounts = useMemo(() => {
    return discounts.map((discount) => {
      const enhancedChildren =
        discount.children?.map((child) => {
          const childProduct = child.product_uuid
            ? products.find((p) => p.uuid === child.product_uuid)
            : null;

          return {
            ...child,
            sale_price: childProduct ? childProduct.price : null,
          };
        }) || [];

      let parentSalePrice = null;
      if (discount.product_uuid) {
        const parentProduct = products.find(
          (p) => p.uuid === discount.product_uuid,
        );
        parentSalePrice = parentProduct ? parentProduct.price : null;
      }

      if (
        (discount.discount_type === 'category' ||
          discount.discount_type === 'selected_products') &&
        enhancedChildren.length > 0
      ) {
        const distinctSalePrices = [
          ...new Set(enhancedChildren.map((c) => c.sale_price)),
        ];

        if (distinctSalePrices.length === 1) {
          parentSalePrice = distinctSalePrices[0];
        } else {
          parentSalePrice = 'Multiple Products';
        }
      }

      if (typeof parentSalePrice === 'number') {
        if (discount.discount_percentage) {
          parentSalePrice =
            parentSalePrice * (1 - discount.discount_percentage / 100);
        } else if (discount.discount_price) {
          parentSalePrice = parentSalePrice - discount.discount_price;
        }
      }

      return {
        ...discount,
        sale_price: parentSalePrice,
        children: enhancedChildren,
      };
    });
  }, [discounts, products]);

  useEffect(() => {
    console.log('Discounts:', discounts);
    console.log('Total Discounts:', total);
  }, [discounts, total]);

  const handleDuplicate = async (uuid) => {
    try {
      const discountData = await getDiscount(uuid);

      const {
        parent_discount: parentDiscount,
        child_discounts: childDiscounts,
      } = discountData;

      const duplicatedData = {
        ...parentDiscount,
        uuid: undefined,
        parent_id: undefined,
        is_active: parentDiscount.is_active,
      };

      if (parentDiscount.discount_type === 'selected_products') {
        delete duplicatedData.product_uuid;
        let productUuids = [];

        if (childDiscounts && childDiscounts.length > 0) {
          productUuids = childDiscounts.map((child) => child.product_uuid);
        } else if (parentDiscount.product_uuid) {
          productUuids = [parentDiscount.product_uuid];
        } else {
          throw new Error(
            'No products found to duplicate for selected_products discount.',
          );
        }

        duplicatedData.product_uuids = productUuids;
      } else if (
        parentDiscount.discount_type === 'all_loyalty_products' ||
        parentDiscount.discount_type === 'all_general_products'
      ) {
        delete duplicatedData.product_uuid;
        delete duplicatedData.product_uuids;
      } else {
        duplicatedData.product_uuid = parentDiscount.product_uuid;
        delete duplicatedData.product_uuids;
      }

      Object.keys(duplicatedData).forEach((key) => {
        if (duplicatedData[key] === undefined) {
          delete duplicatedData[key];
        }
      });

      console.log('Duplicated Data:', duplicatedData);

      const newDiscount = await createProductDiscount(duplicatedData);

      navigate(`/admin/discounts/edit/${newDiscount.parent_discount.uuid}`);

      toast({
        title: 'Discount duplicated successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error duplicating discount:', error);
      toast({
        title: 'Failed to duplicate discount.',
        description:
          error.response?.data?.error ||
          error.message ||
          'An unexpected error occurred.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const columns = useDiscountColumns(
    textColor,
    navigate,
    handleDuplicate,
    loyaltyPrograms,
    loyaltyLevels,
    products,
    isSmallScreen,
  );

  const table = useReactTable({
    data: enhancedDiscounts,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      rowSelection,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(total / pagination.pageSize),
    getSubRows: (row) => row.children || [],
    getRowId: (row) => row.uuid,
  });

  useEffect(() => {
    console.log('Discounts State:', discounts);
  }, [discounts]);

  useEffect(() => {
    const totalPages = table.getPageCount();
    if (pagination.pageIndex >= totalPages && totalPages > 0) {
      setPagination((prev) => ({
        ...prev,
        pageIndex: totalPages - 1,
      }));
    }
  }, [table.getPageCount(), pagination.pageIndex, setPagination]);

  const filteredLoyaltyLevels = useMemo(() => {
    if (loyaltyProgramUUID) {
      return loyaltyLevels.filter(
        (level) => level.loyalty_program_uuid === loyaltyProgramUUID,
      );
    }
    return loyaltyLevels;
  }, [loyaltyLevels, loyaltyProgramUUID]);

  useEffect(() => {
    if (
      loyaltyLevelUUID &&
      !discounts.some((d) => d.loyalty_level_uuid === loyaltyLevelUUID)
    ) {
      const levelName =
        loyaltyLevels.find((level) => level.uuid === loyaltyLevelUUID)?.name ||
        'Unknown';

      toast({
        title: 'Loyalty Level Not Found',
        description: `Loyalty Level "${levelName}" not found in discounts.`,
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      setLoyaltyLevelUUID('');
    }
  }, [discounts, loyaltyLevelUUID, loyaltyLevels, toast]);

  const resetFilters = () => {
    setProductUUID('');
    setLoyaltyProgramUUID('');
    setLoyaltyLevelUUID('');
    setIsActive(true);
    setPagination({ pageIndex: 0, pageSize: 30 });
  };

  const filteredProducts = useMemo(() => {
    return products
      .map((product) => ({
        ...product,
        programType:
          product.loyalty_program_uuid || product.loyalty_level_uuid
            ? 'loyalty'
            : 'regular',
      }))
      .filter((product) => product.programType === 'regular');
  }, [products]);

  const performBulkDelete = async () => {
    const selectedRows = table.getSelectedRowModel().rows;
    const uuids = selectedRows.map((row) => row.original.uuid);
    if (uuids.length === 0) return;

    try {
      await bulkDeleteDiscounts(uuids);
      toast({
        title: 'Discounts deleted.',
        description: `${uuids.length} discounts have been deleted.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setRowSelection({});
      setRefreshKey((prev) => prev + 1);
    } catch (error) {
      toast({
        title: 'Deletion failed.',
        description: error.message || 'Could not delete discounts.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Flex
        direction="column"
        pt={{ base: '120px', md: '75px', xl: '75px' }}
        minH="100vh"
      >
        {/* Use the imported DiscountTemplates component */}
        <DiscountTemplates />

        <Flex direction="column" pt={{ sm: '60px', lg: '30px' }} flex="1">
          <Card
            w="100%"
            px={{ base: '16px', md: '24px' }}
            flex="1"
            overflowX="hidden"
            overflowY="visible"
            display="flex"
            flexDirection="column"
          >
            <Flex
              direction="row"
              my="24px"
              px="20px"
              align="center"
              justify="space-between"
            >
              <DiscountFilters
                productUUID={productUUID}
                setProductUUID={setProductUUID}
                loyaltyProgramUUID={loyaltyProgramUUID}
                setLoyaltyProgramUUID={setLoyaltyProgramUUID}
                loyaltyLevelUUID={loyaltyLevelUUID}
                setLoyaltyLevelUUID={setLoyaltyLevelUUID}
                isActive={isActive}
                setIsActive={setIsActive}
                resetFilters={resetFilters}
                filteredProducts={filteredProducts}
                filteredLoyaltyPrograms={loyaltyPrograms}
                filteredLoyaltyLevels={filteredLoyaltyLevels}
                setPagination={setPagination}
              />

              {/* Group Add and Delete buttons together */}
              <Flex gap={2}>
                <Button
                  leftIcon={<AddIcon />}
                  colorScheme="blue"
                  variant="solid"
                  onClick={() => navigate('/admin/discounts/new-discount')}
                >
                  Add Discount
                </Button>
                <Button
                  leftIcon={<DeleteIcon />}
                  colorScheme="red"
                  variant="outline"
                  onClick={onBulkDeleteOpen}
                  isDisabled={Object.keys(rowSelection).length === 0} // Disable if no selection
                >
                  Delete Selected
                </Button>
              </Flex>
            </Flex>

            {/* Display Error Messages */}
            {(productsError ||
              loyaltyProgramsError ||
              loyaltyLevelsError ||
              discountsError) && (
              <Alert status="error" mb="20px" mx="20px">
                <AlertIcon />
                {productsError ||
                  loyaltyProgramsError ||
                  loyaltyLevelsError ||
                  discountsError}
              </Alert>
            )}

            {discountsLoading ||
            productsLoading ||
            loyaltyProgramsLoading ||
            loyaltyLevelsLoading ? (
              <Flex justify="center" align="center" flex="1">
                <Spinner size="lg" />
              </Flex>
            ) : discounts.length > 0 ? (
              <>
                {/* Table */}
                <DiscountTable
                  table={table}
                  borderColor={borderColor}
                  textColor={textColor}
                  globalFilter={globalFilter}
                />

                {/* Pagination */}
                {total > pagination.pageSize && (
                  <Flex
                    w="100%"
                    justify="space-between"
                    px="20px"
                    pt="10px"
                    pb="5px"
                    alignItems="center"
                    mt="auto"
                  >
                    <Text fontSize="sm" color="gray.500" fontWeight="normal">
                      Showing{' '}
                      {total === 0
                        ? 0
                        : pagination.pageIndex * pagination.pageSize + 1}{' '}
                      to{' '}
                      {Math.min(
                        (pagination.pageIndex + 1) * pagination.pageSize,
                        total,
                      )}{' '}
                      of {total} entries
                    </Text>

                    <Pagination
                      currentPage={pagination.pageIndex}
                      pageCount={table.getPageCount()}
                      onChange={(page) => table.setPageIndex(page)}
                      textColor={textColor}
                      borderColor={borderColor}
                      brandColor={brandColor}
                    />
                  </Flex>
                )}
              </>
            ) : (
              <Text textAlign="center" mt="20px">
                No discounts found.
              </Text>
            )}
            <Outlet />
          </Card>
        </Flex>
      </Flex>

      {/* Bulk Delete Confirmation AlertDialog */}
      <AlertDialog
        isOpen={isBulkDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={onBulkDeleteClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Selected Discounts
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete {Object.keys(rowSelection).length}{' '}
              selected discount
              {Object.keys(rowSelection).length !== 1 ? 's' : ''}? This action
              cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onBulkDeleteClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={async () => {
                  await performBulkDelete();
                  onBulkDeleteClose();
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
