// src/pages/discounts/ProductCheckboxCard.js

import {
  Box,
  Checkbox,
  Text,
  Icon,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaExclamationCircle } from 'react-icons/fa';
import { useState, useEffect } from 'react';

const ProductCheckboxCard = ({
  product,
  isChecked,
  onChange,
  discountKind,
  discountPrice,
  discountPercentage,
}) => {
  const [warningMessage, setWarningMessage] = useState('');

  const borderColor = useColorModeValue(
    isChecked ? 'blue.500' : 'gray.200',
    isChecked ? 'blue.300' : 'gray.600',
  );

  const handleCardClick = () => {
    onChange(product.uuid);
  };

  const isSoldOut =
    product.is_sold_out || product.quantity === 0 || product.quantity === null;

  const calculateSalePrice = () => {
    let salePrice = product.price;

    // Apply existing active discounts
    if (product.discounts && product.discounts.length > 0) {
      const now = new Date();
      const activeDiscount = product.discounts.find((discount) => {
        const startTime = discount.start_time
          ? new Date(discount.start_time)
          : null;
        const endTime = discount.end_time ? new Date(discount.end_time) : null;

        return (
          discount.is_active &&
          (!startTime || now >= startTime) &&
          (!endTime || now <= endTime)
        );
      });

      if (activeDiscount) {
        if (activeDiscount.discount_price) {
          salePrice = product.price - activeDiscount.discount_price;
        } else if (activeDiscount.discount_percentage) {
          salePrice =
            product.price * (1 - activeDiscount.discount_percentage / 100);
        }
      }
    }

    // Apply the current discount being edited if the product is selected
    if (isChecked) {
      if (discountKind === 'fixed_price' && discountPrice) {
        salePrice = product.price - parseFloat(discountPrice);
      } else if (discountKind === 'percentage' && discountPercentage) {
        salePrice = product.price * (1 - parseFloat(discountPercentage) / 100);
      }
    }

    if (salePrice > product.price) {
      salePrice = product.price;
    } else if (salePrice < 0) {
      salePrice = 0;
    }

    return salePrice;
  };

  const salePrice = calculateSalePrice();

  useEffect(() => {
    if (salePrice > product.price) {
      setWarningMessage('Sale price cannot exceed the original price.');
    } else if (salePrice < 0) {
      setWarningMessage('Sale price cannot be below zero.');
    } else {
      setWarningMessage('');
    }
  }, [salePrice, product.price]);

  return (
    <Box
      borderWidth="1px"
      borderRadius="md"
      borderColor={borderColor}
      p={4}
      cursor="pointer"
      onClick={handleCardClick}
    >
      <Flex alignItems="center">
        <Checkbox
          isChecked={isChecked}
          onChange={(e) => {
            e.stopPropagation();
            onChange(product.uuid);
          }}
          mr={2}
        />
        <Text fontWeight="bold">{product.name}</Text>
      </Flex>
      {product.price !== undefined && (
        <Text>Regular Price: €{product.price.toFixed(2)}</Text>
      )}
      {salePrice !== undefined && salePrice !== product.price && (
        <Text color="green.500">Sale Price: €{salePrice.toFixed(2)}</Text>
      )}
      {!isSoldOut && <Text>Quantity: {product.quantity}</Text>}
      {isSoldOut && (
        <Flex alignItems="center" color="red.500" mt={2}>
          <Icon as={FaExclamationCircle} mr={1} />
          <Text>Sold Out</Text>
        </Flex>
      )}
      {warningMessage && (
        <Text color="yellow.500" mt={2}>
          {warningMessage}
        </Text>
      )}
    </Box>
  );
};

export default ProductCheckboxCard;
