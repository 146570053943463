import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAutomation } from '../../contexts/AutomationContext';
import {
  Grid,
  Box,
  useDisclosure,
  useBreakpointValue,
  useToast,
  VStack,
  HStack,
  Button,
  Spacer,
  Icon,
  Select,
  Input,
  IconButton,
  Text,
  Switch,
} from '@chakra-ui/react';

import { AddIcon, EditIcon, CheckIcon } from '@chakra-ui/icons';
import FlowItem from './FlowItem';
import {
  fetchAutomations,
  fetchAutomationRules,
  createLoyaltyAutomationRule,
  updateLoyaltyAutomationRule,
  updateLoyaltyAutomation,
  deleteLoyaltyAutomationRule,
  deleteLoyaltyAutomation,
  fetchLoyaltyPrograms,
  fetchVouchers,
} from '../../api/automation';
import FlowSummary from '../../components/automation/FlowSummary';
import FlowModal from '../../components/automation/FlowModal';
import DeleteFlowModal from '../../components/automation/DeleteFlowModal';
import { getIconBetweenSteps } from 'utils/automation/automationUtils';
import {
  renderConditionContent,
  renderConditionInputs,
} from 'utils/automation/condtionalRenderers';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';
import { getCompany } from 'api/company';

export default function AutomationBuilder() {
  const {
    flowName,
    setFlowName,
    flowDescription,
    setFlowDescription,
    flowItems = [],
    setFlowItems,
    automationUuid,
    setAutomationUuid,
  } = useAutomation();
  const { uuid } = useParams();

  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const stepTemplate = {
    uuid: '',
    automation_uuid: '',
    step_type: 'IF_AND' || 'IF_OR' || 'THEN',
    condition_type: '',
    loyalty_program_uuid: '',
    loyalty_level_uuid: '',
    spent_amount: null,
    time_period: null,
    time_unit: '',
    reward_product_uuid: '',
    reward_quantity: null,
  };
  const [defaultLanguage, setDefaultLanguage] = useState('en');
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [isIntervalUpdating, setIsIntervalUpdating] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [interval, setInterval] = useState(30);
  const [lastRun, setLastRun] = useState(null);
  const [nextRunDate, setNextRunDate] = useState(null);
  const [loyaltyPrograms, setLoyaltyPrograms] = useState([]);
  const [vouchers, setVouchers] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [currentStep, setCurrentStep] = useState({
    ...stepTemplate,
  });

  const toast = useToast();

  const gridTemplateColumns = useBreakpointValue({
    base: '1fr',
    md: 'repeat(2, 1fr)',
  });

  const gaEventTracker = useAnalyticsEventTracker('Automations');

  useEffect(() => {
    if (uuid) {
      const fetchAutomation = async () => {
        try {
          const companyDefaultLanguage = await getCompany().then(
            (company) => company.default_language,
          );
          setDefaultLanguage(companyDefaultLanguage);
          const automations = await fetchAutomations();
          const automation = automations.find((auto) => auto.uuid === uuid);
          if (automation) {
            setFlowName(automation.name);
            setFlowDescription(automation.description);
            setAutomationUuid(uuid);
            setInterval(automation.automation_interval);
            setLastRun(automation.last_run);

            // Calculate initial next run date
            const nextRun = new Date(automation.last_run || new Date());
            nextRun.setDate(nextRun.getDate() + automation.automation_interval);
            setNextRunDate(nextRun.toDateString());

            const rules = await fetchAutomationRules(uuid);
            setFlowItems(rules);
          } else {
            console.error('Automation not found');
          }
        } catch (error) {
          console.error('Error fetching automation:', error);
        }
      };
      fetchAutomation();
    } else {
      setFlowName('New Automation');
      setFlowDescription('Description');
    }
    const fetchData = async () => {
      try {
        const fetchedLoyaltyPrograms = await fetchLoyaltyPrograms();
        const fetchedVouchers = await fetchVouchers();
        setLoyaltyPrograms(fetchedLoyaltyPrograms);
        setVouchers(fetchedVouchers);
      } catch (error) {
        console.error('Error fetching loyalty programs or vouchers', error);
      }
    };

    fetchData();
  }, [uuid, setFlowName, setFlowItems, setAutomationUuid, setFlowDescription]);

  const openAddModal = () => {
    if (
      flowItems.length > 0 &&
      flowItems[flowItems.length - 1].step_type === 'THEN'
    ) {
      toast({
        title: 'Action not allowed',
        description: "Cannot add more steps after a 'THEN' step.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setCurrentStep({
      ...stepTemplate,
    });
    setIsEditing(false);
    onOpen();
  };

  const openEditModal = (index) => {
    const step = flowItems[index];
    setCurrentStep({
      ...stepTemplate,
      ...step,
    });
    setEditIndex(index);
    setIsEditing(true);
    onOpen();
  };

  const calculateNextRunDate = (lastRunDate, intervalDays) => {
    const baseDate = lastRunDate ? new Date(lastRunDate) : new Date();
    const nextDate = new Date(baseDate);
    nextDate.setDate(nextDate.getDate() + intervalDays);
    return nextDate.toDateString();
  };

  const handleSetNewInterval = async (selectedValue) => {
    setIsIntervalUpdating(true);
    const newIntervalDays = intervalInDays(selectedValue);

    try {
      await updateLoyaltyAutomation({
        uuid: automationUuid,
        automation_interval: newIntervalDays,
      });

      setInterval(newIntervalDays);
      const nextDate = calculateNextRunDate(lastRun, newIntervalDays);
      setNextRunDate(nextDate);
      // Success toast...
      toast({
        title: 'Success',
        description: 'Interval updated successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      // Error handling...
      toast({
        title: 'Error',
        description: 'There was an error updating the interval.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsIntervalUpdating(false);
    }
  };

  const handleSave = async () => {
    const newItem = {
      ...currentStep,
      automation_uuid: automationUuid,
    };

    if (isEditing) {
      await updateLoyaltyAutomationRule(newItem);
      const updatedFlowItems = [...flowItems];
      updatedFlowItems[editIndex] = newItem;
      setFlowItems(updatedFlowItems);
    } else {
      const addedStep = await createLoyaltyAutomationRule(newItem);
      setFlowItems([...flowItems, addedStep]);
    }

    setCurrentStep({
      ...stepTemplate,
    });

    onClose();
  };

  const handleDelete = async (index) => {
    const step = flowItems[index];
    try {
      await deleteLoyaltyAutomationRule(step.uuid);
      const updatedFlowItems = flowItems.filter((_, i) => i !== index);
      setFlowItems(updatedFlowItems);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'There was an error deleting the step.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleNameEdit = () => {
    setIsEditingName(!isEditingName);
  };

  const handleNameSave = async (e) => {
    if (e.key === 'Enter' || e.type === 'click') {
      setIsEditingName(false);
      try {
        await updateLoyaltyAutomation({ name: flowName, uuid: automationUuid });
        toast({
          title: 'Success',
          description: 'Name updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Error',
          description: 'There was an error updating the name.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleDescriptionEdit = () => {
    setIsEditingDescription(!isEditingDescription);
  };

  const handleDescriptionSave = async (e) => {
    if (e.key === 'Enter' || e.type === 'click') {
      setIsEditingDescription(false);
      try {
        await updateLoyaltyAutomation({
          uuid: automationUuid,
          description: flowDescription,
        });
        toast({
          title: 'Success',
          description: 'Description updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Error',
          description: 'There was an error updating the description.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleActiveToggle = async () => {
    setIsActive(!isActive);
    try {
      await updateLoyaltyAutomation({
        uuid: automationUuid,
        is_active: !isActive,
      });
      toast({
        title: 'Success',
        description: `Automation is now ${!isActive ? 'active' : 'inactive'}`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'There was an error updating the automation status.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteFlow = async () => {
    for (const item of flowItems) {
      await deleteLoyaltyAutomationRule(item.uuid);
    }
    await deleteLoyaltyAutomation(automationUuid);
    onDeleteClose();
    navigate('/admin/automations');
  };

  const intervalInDays = (intervalWord) => {
    const intervals = {
      Day: 1,
      Month: 30,
      Quarter: 90,
      Year: 365,
    };
    return intervals[intervalWord] || parseInt(intervalWord) || 30;
  };

  const intervalDaysInWords = (days) => {
    switch (Number(days)) {
      case 1:
        return 'Day';
      case 30:
        return 'Month';
      case 90:
        return 'Quarter';
      case 365:
        return 'Year';
      default:
        return 'Month';
    }
  };

  return (
    <Box pt={{ base: '100px', md: '80px', xl: '80px' }} px={5} mt={2}>
      <Grid templateColumns={gridTemplateColumns} gap={8}>
        <Box>
          <VStack spacing={4} align="stretch" height="100%" width="90%">
            <Spacer flex="2" />

            <HStack align="strech" w="100%">
              <VStack spacing={0.2} align="stretch">
                <HStack spacing={1}>
                  {isEditingName ? (
                    <Input
                      value={flowName}
                      onChange={(e) => setFlowName(e.target.value)}
                      fontWeight="bold"
                      fontSize="xl"
                      variant="unstyled"
                      onKeyDown={handleNameSave}
                      autoFocus
                    />
                  ) : (
                    <Text
                      fontWeight="bold"
                      fontSize="xl"
                      onClick={handleNameEdit}
                    >
                      {flowName}
                    </Text>
                  )}
                  <IconButton
                    icon={isEditingName ? <CheckIcon /> : <EditIcon />}
                    onClick={isEditingName ? handleNameSave : handleNameEdit}
                  />
                </HStack>
                <HStack spacing={1}>
                  {isEditingDescription ? (
                    <Input
                      value={flowDescription}
                      onChange={(e) => setFlowDescription(e.target.value)}
                      variant="unstyled"
                      onKeyDown={handleDescriptionSave}
                      autoFocus
                    />
                  ) : (
                    <Text onClick={handleDescriptionEdit}>
                      {flowDescription}
                    </Text>
                  )}
                  <IconButton
                    icon={isEditingDescription ? <CheckIcon /> : <EditIcon />}
                    onClick={
                      isEditingDescription
                        ? handleDescriptionSave
                        : handleDescriptionEdit
                    }
                  />
                </HStack>
              </VStack>

              <Spacer />

              <VStack align="stretch" spacing={2} pl={4} minW="150px">
                <HStack>
                  <Text>Run every</Text>
                  <Switch
                    isChecked={isActive}
                    onChange={
                      isIntervalUpdating ? () => {} : handleActiveToggle
                    }
                  />
                </HStack>
                <Select
                  isDisabled={!isActive || isIntervalUpdating}
                  value={intervalDaysInWords(interval)}
                  onChange={(e) => handleSetNewInterval(e.target.value)}
                  isLoading={isIntervalUpdating}
                >
                  <option value="Day">Day</option>
                  <option value="Month">Month</option>
                  <option value="Quarter">Quarter</option>
                  <option value="Year">Year</option>
                </Select>
              </VStack>
              {nextRunDate && (
                <VStack
                  align="stretch"
                  spacing={2}
                  borderLeft="1px solid"
                  borderColor="gray.200"
                  pl={4}
                  minW="120px"
                >
                  <Text mt={2} color="gray.500">
                    Next run date:
                  </Text>
                  <Text color="gray.500">{nextRunDate}</Text>
                </VStack>
              )}
            </HStack>
            {flowItems.map(
              (item, index) =>
                item && (
                  <React.Fragment key={index}>
                    <FlowItem
                      type={item.step_type}
                      content={renderConditionContent(
                        item,
                        loyaltyPrograms,
                        vouchers,
                        defaultLanguage,
                      )}
                      onEdit={() => openEditModal(index)}
                      onDelete={() => handleDelete(index)}
                    />
                    {index < flowItems.length - 1 && flowItems[index + 1] && (
                      <Box display="flex" justifyContent="center" my={2}>
                        <Icon
                          as={getIconBetweenSteps(
                            item.step_type,
                            flowItems[index + 1].step_type,
                          )}
                          boxSize={6}
                        />
                      </Box>
                    )}
                  </React.Fragment>
                ),
            )}

            <Spacer flex="2" />

            <Button
              leftIcon={<AddIcon />}
              onClick={() => {
                gaEventTracker('New step');
                openAddModal();
              }}
            >
              New step
            </Button>

            <Spacer flex="2" />

            <HStack width="100%" justifyContent="space-around">
              <Button
                backgroundColor="#6789FF"
                color="white"
                width="70%"
                onClick={() => navigate('/admin/automations')}
              >
                Save
              </Button>
              <Button
                backgroundColor="#FF6433"
                color="white"
                width="70%"
                onClick={onDeleteOpen}
              >
                Delete flow
              </Button>
            </HStack>
          </VStack>
        </Box>
        <FlowSummary flowItems={flowItems} />
      </Grid>

      <FlowModal
        isOpen={isOpen}
        onClose={onClose}
        isEditing={isEditing}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        handleSave={handleSave}
        renderConditionInputs={() =>
          renderConditionInputs(
            currentStep,
            setCurrentStep,
            loyaltyPrograms,
            vouchers,
            toast,
            defaultLanguage,
          )
        }
      />
      <DeleteFlowModal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        handleDeleteFlow={handleDeleteFlow}
      />
    </Box>
  );
}
