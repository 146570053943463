import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Input,
  Textarea,
  Switch,
  Select,
  Button,
  Spinner,
  Collapse,
  IconButton,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import Card from '../card/Card';
import { useNavigate } from 'react-router-dom';

import Details from '../products/Details';
import CategoryTreeSelect from '../products/CategoryTreeSelect';

export default function ProductForm({
  languages,
  translations,
  handleTranslationChange,
  isFeatured,
  setIsFeatured,
  quantity,
  soldQuantity,
  handleQuantityChange,
  selectedCategory,
  categoriesTree,
  isLoadingCategories,
  handleCategoryChange,
  productType,
  setProductType,
  visibility,
  setVisibility,
  productPrice,
  setProductPrice,
  saveAction,
  leftChildren,
  children,
  additionalFields,
  setAdditionalFields,
}) {
  const navigate = useNavigate();
  const [isAdditionalFieldsOpen, setIsAdditionalFieldsOpen] = useState(false);

  const toggleAdditionalFields = () => {
    setIsAdditionalFieldsOpen(!isAdditionalFieldsOpen);
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction={{ base: 'column', xl: 'row' }} gap="20px">
        {/* Left Column: Product Details with Image Gallery Above */}
        <Flex direction="column" flex="1">
          {leftChildren && <Box mb="20px">{leftChildren}</Box>}
          <Card p="30px" mb="20px">
            <Flex direction="column">
              <Tabs variant="enclosed" colorScheme="blue" mb="20px">
                <TabList>
                  {languages.map((lang) => (
                    <Tab key={lang.code}>{lang.name}</Tab>
                  ))}
                </TabList>
                <TabPanels>
                  {languages.map((lang) => (
                    <TabPanel key={lang.code}>
                      <Box mb="20px">
                        <Text mb="10px">Product Name</Text>
                        <Input
                          placeholder="Product Name"
                          value={translations[lang.code]?.name || ''}
                          onChange={(e) =>
                            handleTranslationChange(
                              lang.code,
                              'name',
                              e.target.value,
                            )
                          }
                        />
                      </Box>
                      <Box mb="20px">
                        <Text mb="10px">Product Description</Text>
                        <Textarea
                          placeholder="Product Description"
                          value={translations[lang.code]?.description || ''}
                          onChange={(e) =>
                            handleTranslationChange(
                              lang.code,
                              'description',
                              e.target.value,
                            )
                          }
                        />
                      </Box>
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>

              {/* Category Selection */}
              <Box mb="20px">
                <Text mb="10px" fontWeight="bold">
                  Select Category
                </Text>
                {isLoadingCategories ? (
                  <Flex justify="center" align="center" height="100px">
                    <Spinner />
                  </Flex>
                ) : (
                  <CategoryTreeSelect
                    categories={categoriesTree}
                    selectedCategoryUuid={selectedCategory}
                    onSelectCategory={handleCategoryChange}
                  />
                )}
              </Box>

              {/* Product Type Selection */}
              <Box mb="20px">
                <Text mb="10px" fontWeight="bold">
                  Product Type
                </Text>
                <Select
                  placeholder="Select product type"
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                >
                  <option value="physical">Physical</option>
                  <option value="virtual">Virtual</option>
                  <option value="downloadable">Downloadable</option>
                </Select>
              </Box>

              {/* Visibility Selection */}
              <Box mb="20px">
                <Text mb="10px" fontWeight="bold">
                  Visibility
                </Text>
                <Select
                  value={visibility}
                  onChange={(e) => setVisibility(e.target.value)}
                >
                  <option value="public">Public</option>
                  <option value="private">Private</option>
                  <option value="hidden">Hidden</option>
                </Select>
              </Box>

              {/* Quantity and Sold Quantity */}
              <Box mb="20px">
                <Text mb="10px">Stock Quantity</Text>
                <Input
                  type="number"
                  placeholder="Quantity"
                  value={quantity}
                  onChange={(e) => handleQuantityChange(e.target.value)}
                />
                <Text mt="10px">
                  <strong>Sold Quantity:</strong> {soldQuantity}
                </Text>
              </Box>

              {/* Featured Switch */}
              <Flex align="center" mt="10px">
                <Text fontWeight="bold" mr="10px">
                  Featured Product
                </Text>
                <Switch
                  id="isFeatured"
                  isChecked={isFeatured}
                  onChange={(e) => setIsFeatured(e.target.checked)}
                />
              </Flex>
            </Flex>
          </Card>
        </Flex>

        {/* Right Column: Additional Elements */}
        <Flex direction="column" flex="1">
          {children /* e.g. Dropzone, Images card additional content */}
          <Details
            productPrice={productPrice}
            setProductPrice={setProductPrice}
          />

          {/* Additional Fields */}
          <Card p="30px" mb="20px">
            <Flex justify="space-between" align="center" mb="20px">
              <Text fontSize="xl" fontWeight="bold">
                Additional Fields
              </Text>
              <IconButton
                aria-label={isAdditionalFieldsOpen ? 'Collapse' : 'Expand'}
                icon={
                  isAdditionalFieldsOpen ? (
                    <ChevronUpIcon />
                  ) : (
                    <ChevronDownIcon />
                  )
                }
                onClick={toggleAdditionalFields}
                size="sm"
              />
            </Flex>
            <Collapse in={isAdditionalFieldsOpen}>
              <Box mb="20px">
                <Text mb="10px">SKU</Text>
                <Input
                  placeholder="SKU"
                  value={additionalFields?.sku || ''}
                  onChange={(e) =>
                    setAdditionalFields((prev) => ({
                      ...prev,
                      sku: e.target.value,
                    }))
                  }
                />
              </Box>
              <Box mb="20px">
                <Text mb="10px">ISBN</Text>
                <Input
                  placeholder="ISBN"
                  value={additionalFields?.isbn || ''}
                  onChange={(e) =>
                    setAdditionalFields((prev) => ({
                      ...prev,
                      isbn: e.target.value,
                    }))
                  }
                />
              </Box>
              <Box mb="20px">
                <Text mb="10px">EAN/UPC</Text>
                <Input
                  placeholder="EAN/UPC"
                  value={additionalFields?.ean_upc || ''}
                  onChange={(e) =>
                    setAdditionalFields((prev) => ({
                      ...prev,
                      ean_upc: e.target.value,
                    }))
                  }
                />
              </Box>
              <Box mb="20px">
                <Text mb="10px">External ID</Text>
                <Input
                  placeholder="External ID"
                  value={additionalFields?.external_id || ''}
                  onChange={(e) =>
                    setAdditionalFields((prev) => ({
                      ...prev,
                      external_id: e.target.value,
                    }))
                  }
                />
              </Box>
              <Box mb="20px">
                <Text mb="10px">Weight (g)</Text>
                <Input
                  type="number"
                  placeholder="Weight"
                  value={additionalFields?.weight || ''}
                  onChange={(e) =>
                    setAdditionalFields((prev) => ({
                      ...prev,
                      weight: e.target.value,
                    }))
                  }
                />
              </Box>
              <Box mb="20px">
                <Text mb="10px">Weight Unit (g)</Text>
                <Input
                  placeholder="Weight Unit"
                  value={additionalFields?.weight_unit || ''}
                  onChange={(e) =>
                    setAdditionalFields((prev) => ({
                      ...prev,
                      weight_unit: e.target.value,
                    }))
                  }
                />
              </Box>
              <Box mb="20px">
                <Text mb="10px">External URL</Text>
                <Input
                  placeholder="External URL"
                  value={additionalFields?.external_url || ''}
                  onChange={(e) =>
                    setAdditionalFields((prev) => ({
                      ...prev,
                      external_url: e.target.value,
                    }))
                  }
                />
              </Box>
              <Box mb="20px">
                <Text mb="10px">Tax Class</Text>
                <Input
                  placeholder="Tax Class"
                  value={additionalFields?.tax_class || ''}
                  onChange={(e) =>
                    setAdditionalFields((prev) => ({
                      ...prev,
                      tax_class: e.target.value,
                    }))
                  }
                />
              </Box>
              <Box mb="20px">
                <Text mb="10px">Country of Origin</Text>
                <Input
                  placeholder="Country of Origin"
                  maxLength={3}
                  value={additionalFields?.country_of_origin || ''}
                  onChange={(e) =>
                    setAdditionalFields((prev) => ({
                      ...prev,
                      country_of_origin: e.target.value,
                    }))
                  }
                />
              </Box>
              <Box mb="20px">
                <Text mb="10px">Brand Name</Text>
                <Input
                  placeholder="Brand Name"
                  value={additionalFields?.brand_name || ''}
                  onChange={(e) =>
                    setAdditionalFields((prev) => ({
                      ...prev,
                      brand_name: e.target.value,
                    }))
                  }
                />
              </Box>
              <Box mb="20px">
                <Text mb="10px">Dimensions</Text>
                <Input
                  placeholder="Dimensions"
                  value={additionalFields?.dimensions || ''}
                  onChange={(e) =>
                    setAdditionalFields((prev) => ({
                      ...prev,
                      dimensions: e.target.value,
                    }))
                  }
                />
              </Box>
            </Collapse>
          </Card>

          <Button
            mt="20px"
            colorScheme="blue"
            onClick={saveAction}
            isDisabled={!productPrice}
          >
            Save Changes
          </Button>

          <Button mt="4" colorScheme="red" onClick={() => navigate(-1)}>
            Go Back
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}
