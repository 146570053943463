import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Image,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
  Box,
  useToast,
  Flex,
} from '@chakra-ui/react';
import DropzoneLoyalty from './LoyaltyDropzone';
import { deleteLoyaltyLevelArt, updateLoyaltyLevelArt } from 'api/loyalty';

export default function LoyaltyImageModal({
  isOpen,
  onClose,
  image,
  setSelectedImage,
  levelUuid,
  onImageUpdate,
  onImageDelete,
}) {
  const [isReplacing, setIsReplacing] = useState(false);
  const [occurrencePercentage, setOccurrencePercentage] = useState(
    image?.occurrence_percentage || 0,
  );
  const [isSaving, setIsSaving] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const toast = useToast();

  useEffect(() => {
    if (image) {
      setOccurrencePercentage(image.occurrence_percentage || 0);
    }
  }, [image]);

  const handleSliderChange = (value) => {
    setOccurrencePercentage(value);
    setHasChanged(true);
  };

  const handleDelete = async () => {
    try {
      await deleteLoyaltyLevelArt(levelUuid, image.uuid);
      onImageDelete(image.uuid);
      onClose();
      toast({
        title: 'Success',
        description: 'Image deleted successfully',
        status: 'success',
        duration: 3000,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to delete image',
        status: 'error',
        duration: 3000,
      });
    }
  };

  const handleReplace = async (files) => {
    setIsSaving(true);
    try {
      const newImageUrl = URL.createObjectURL(files[0]);
      const response = await updateLoyaltyLevelArt(
        levelUuid,
        image.uuid,
        occurrencePercentage,
        files[0],
      );

      if (!response) {
        throw new Error('No response received from server');
      }

      const updatedImage = {
        ...response,
        image_url: response.image_url || newImageUrl,
      };

      onImageUpdate(updatedImage);
      setSelectedImage(updatedImage);
      setIsReplacing(false);

      const preloadImage = document.createElement('img');
      preloadImage.onload = () => {
        URL.revokeObjectURL(newImageUrl);
        onImageUpdate(updatedImage);
        setSelectedImage(updatedImage);
        setIsReplacing(false);
      };
      preloadImage.src = response.image_url;
      toast({
        title: 'Success',
        description: 'Image replaced successfully',
        status: 'success',
        duration: 3000,
      });
    } catch (error) {
      console.error(`Error replacing image: ${error.message}`);
      toast({
        title: 'Error',
        description: 'Failed to replace image',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const response = await updateLoyaltyLevelArt(
        levelUuid,
        image.uuid,
        occurrencePercentage,
      );
      onImageUpdate(response);
      toast({
        title: 'Success',
        description: 'Image updated successfully',
        status: 'success',
        duration: 3000,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update image',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setIsSaving(false);
      if (hasChanged) {
        setHasChanged(false);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Art Image</ModalHeader>
        <ModalBody>
          {!isReplacing ? (
            <Image
              src={image?.image_url}
              alt="Level art"
              w="100%"
              borderRadius="md"
            />
          ) : (
            <DropzoneLoyalty
              onDrop={handleReplace}
              height="300px"
              width="100%"
              iconSize="40px"
              maxFiles={1}
            />
          )}

          <Box mt={4}>
            <Flex justify="space-between" align="center" mb={4}>
              <Text>Occurrence Percentage: {occurrencePercentage}%</Text>
              {hasChanged && (
                <Button
                  size="sm"
                  colorScheme="blue"
                  onClick={handleSave}
                  isLoading={isSaving}
                >
                  Set
                </Button>
              )}
            </Flex>
            <Slider
              value={occurrencePercentage}
              onChange={handleSliderChange}
              min={0}
              max={100}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Flex w="100%" justify="space-between">
            <Button colorScheme="red" onClick={handleDelete}>
              Delete
            </Button>
            <Box>
              {!isReplacing ? (
                <Button
                  colorScheme="orange"
                  mr={3}
                  onClick={() => setIsReplacing(true)}
                >
                  Replace
                </Button>
              ) : (
                <>
                  <Button
                    colorScheme="gray"
                    mr={3}
                    onClick={() => setIsReplacing(false)}
                  >
                    Cancel Replace
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={handleSave}
                    isLoading={isSaving}
                  >
                    Save Changes
                  </Button>
                </>
              )}
            </Box>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
