import React, { useEffect } from 'react';
import {
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

const MagnifiableImage = ({ src, alt, onNext, onPrev, ...rest }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowLeft' && onPrev) {
        onPrev();
      } else if (e.key === 'ArrowRight' && onNext) {
        onNext();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onPrev, onNext]);

  return (
    <>
      <Image
        src={src}
        alt={alt}
        cursor="zoom-in"
        onClick={onOpen}
        transition="transform 0.2s"
        _hover={{ transform: 'scale(1.02)' }}
        {...rest}
      />
      <Modal isOpen={isOpen} onClose={onClose} size="full" isCentered>
        <ModalOverlay />
        <ModalContent bg="transparent" boxShadow="none">
          <ModalBody
            p={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
            onClick={onClose}
          >
            {}
            {onPrev && (
              <IconButton
                icon={<ChevronLeftIcon />}
                position="absolute"
                left="20px"
                top="50%"
                transform="translateY(-50%)"
                zIndex="10"
                variant="ghost"
                onClick={(e) => {
                  e.stopPropagation();
                  onPrev();
                }}
                aria-label="Previous Image"
              />
            )}

            <Image
              src={src}
              alt={alt}
              maxW="90%"
              maxH="90%"
              objectFit="contain"
              onClick={(e) => e.stopPropagation()}
            />

            {}
            {onNext && (
              <IconButton
                icon={<ChevronRightIcon />}
                position="absolute"
                right="20px"
                top="50%"
                transform="translateY(-50%)"
                zIndex="10"
                variant="ghost"
                onClick={(e) => {
                  e.stopPropagation();
                  onNext();
                }}
                aria-label="Next Image"
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MagnifiableImage;
