// src/components/discounts/DiscountTable.jsx
import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Flex } from '@chakra-ui/react';
import { flexRender } from '@tanstack/react-table';
import { MdExpandMore, MdChevronRight } from 'react-icons/md';

const DiscountTable = ({ table, borderColor, textColor, globalFilter }) => {
  const renderRow = (row, level = 0) => (
    <React.Fragment key={row.id}>
      <Tr>
        {row.getVisibleCells().map((cell) => (
          <Td
            key={cell.id}
            borderColor={borderColor}
            pl={cell.column.id === 'expander' ? `${level * 20}px` : undefined}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        ))}
      </Tr>
    </React.Fragment>
  );

  return (
    <Table variant="simple" color="gray.500" mb="24px">
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Th
                key={header.id}
                borderColor={borderColor}
                cursor={header.column.getCanSort() ? 'pointer' : 'default'}
              >
                <Flex align="center" justify="space-between">
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                  {header.column.getCanSort() && (
                    <Flex align="center">
                      {header.column.getIsSorted() === 'asc' ? (
                        <MdExpandMore />
                      ) : header.column.getIsSorted() === 'desc' ? (
                        <MdChevronRight />
                      ) : null}
                    </Flex>
                  )}
                </Flex>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>{table.getRowModel().rows.map((row) => renderRow(row))}</Tbody>
    </Table>
  );
};

export default DiscountTable;
