// CategoryTreeSelect.js
import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  Box,
  Checkbox,
  Flex,
  Text,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Divider,
  Button,
  Icon,
  Collapse,
  Input,
} from '@chakra-ui/react';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  SearchIcon,
} from '@chakra-ui/icons';
import PropTypes from 'prop-types';

const CategoryTreeSelect = ({
  categories,
  selectedCategoryUuid,
  onSelectCategory,
  totalCount,
}) => {
  const [expandedNodes, setExpandedNodes] = useState([]);
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const selectedRef = useRef(null);

  // Find all parent UUIDs of the selected category
  const findParentNodes = (categoriesList, targetUuid, parents = []) => {
    for (const category of categoriesList) {
      if (category.value === targetUuid) {
        return parents; // Return parent nodes if found
      }
      if (category.children && category.children.length > 0) {
        const result = findParentNodes(category.children, targetUuid, [
          ...parents,
          category.value,
        ]);
        if (result) return result;
      }
    }
    return null;
  };

  // Find the path for breadcrumbs
  const findCategoryPath = (categoriesList, targetUuid, path = []) => {
    for (const category of categoriesList) {
      const newPath = [...path, category];
      if (category.value === targetUuid) {
        return newPath;
      }
      if (category.children && category.children.length > 0) {
        const result = findCategoryPath(category.children, targetUuid, newPath);
        if (result) return result;
      }
    }
    return null;
  };

  // Function to collect all category UUIDs that have children
  const getAllExpandableCategoryUuids = (categoriesList) => {
    let uuids = [];
    categoriesList.forEach((category) => {
      if (category.children && category.children.length > 0) {
        uuids.push(category.value);
        uuids = uuids.concat(getAllExpandableCategoryUuids(category.children));
      }
    });
    return uuids;
  };

  // Handler to expand all categories
  const handleExpandAll = () => {
    const allExpandableUuids = getAllExpandableCategoryUuids(categories);
    setExpandedNodes(allExpandableUuids);
    setIsAllExpanded(true);
  };

  // Handler to collapse all categories
  const handleCollapseAll = () => {
    setExpandedNodes([]);
    setIsAllExpanded(false);
  };

  useEffect(() => {
    if (selectedCategoryUuid) {
      const parents = findParentNodes(categories, selectedCategoryUuid);
      setExpandedNodes((prev) => [...new Set([...prev, ...(parents || [])])]);
    }
  }, [categories, selectedCategoryUuid]);

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [selectedCategoryUuid, expandedNodes]);

  const renderCategories = (categoriesList) => {
    return categoriesList.map((category) => {
      const isExpanded = expandedNodes.includes(category.value);
      const hasChildren = category.children && category.children.length > 0;

      const toggleExpand = () => {
        if (isExpanded) {
          setExpandedNodes((prev) =>
            prev.filter((uuid) => uuid !== category.value),
          );
        } else {
          setExpandedNodes((prev) => [...prev, category.value]);
        }
      };

      // Highlight search match
      const isMatch =
        searchQuery.length > 0 &&
        category.label.toLowerCase().includes(searchQuery.toLowerCase());

      return (
        <Box key={category.value} pl={4} py={1}>
          <Flex align="center" justify="space-between">
            <Flex align="center">
              {hasChildren && (
                <Icon
                  as={isExpanded ? ChevronDownIcon : ChevronRightIcon}
                  mr={2}
                  cursor="pointer"
                  onClick={toggleExpand}
                />
              )}
              {!hasChildren && <Box width="24px" mr={2} />}{' '}
              {/* Placeholder for alignment */}
              <Checkbox
                isChecked={selectedCategoryUuid === category.value}
                onChange={() => {
                  if (selectedCategoryUuid === category.value) {
                    onSelectCategory(''); // Deselect if already selected
                  } else {
                    onSelectCategory(category.value);
                  }
                }}
                mr={2}
                pointerEvents="auto"
              />
              <Text
                fontWeight={
                  selectedCategoryUuid === category.value ? 'bold' : 'normal'
                }
                color={
                  selectedCategoryUuid === category.value
                    ? 'blue.600'
                    : 'inherit'
                }
                onClick={() => {
                  if (hasChildren) {
                    toggleExpand();
                  } else {
                    onSelectCategory(category.value);
                  }
                }}
                cursor={hasChildren ? 'pointer' : 'default'}
              >
                {isMatch ? (
                  <Box as="span" bg="yellow.200">
                    {category.label} ({category.totalCount})
                  </Box>
                ) : (
                  `${category.label} (${category.totalCount})`
                )}
              </Text>
            </Flex>
          </Flex>
          {hasChildren && (
            <Collapse in={isExpanded} animateOpacity>
              {renderCategories(category.children)}
            </Collapse>
          )}
        </Box>
      );
    });
  };

  // Function to filter categories based on search query
  const filterCategories = (categoriesList, query) => {
    const filtered = [];

    categoriesList.forEach((category) => {
      if (category.label.toLowerCase().includes(query.toLowerCase())) {
        filtered.push(category);
      } else if (category.children && category.children.length > 0) {
        const filteredChildren = filterCategories(category.children, query);
        if (filteredChildren.length > 0) {
          filtered.push({ ...category, children: filteredChildren });
        }
      }
    });

    return filtered;
  };

  // Memoize filtered categories for performance
  const filteredCategories = useMemo(() => {
    if (searchQuery.trim() === '') {
      return categories;
    }
    return filterCategories(categories, searchQuery.trim());
  }, [categories, searchQuery]);

  // Automatically expand nodes when searching
  useEffect(() => {
    if (searchQuery.trim() === '') {
      // Reset to previous expansion state if not searching
      if (selectedCategoryUuid) {
        const parents = findParentNodes(categories, selectedCategoryUuid);
        setExpandedNodes((prev) => [...new Set([...prev, ...(parents || [])])]);
      }
    } else {
      // Expand all nodes to show search results
      const expandAllMatching = (categoriesList) => {
        let uuids = [];
        categoriesList.forEach((category) => {
          if (category.children && category.children.length > 0) {
            uuids.push(category.value);
            uuids = uuids.concat(expandAllMatching(category.children));
          }
        });
        return uuids;
      };
      const allExpandable = getAllExpandableCategoryUuids(filteredCategories);
      setExpandedNodes(allExpandable);
    }
  }, [searchQuery, categories, filteredCategories, selectedCategoryUuid]);

  return (
    <Box
      border="1px"
      borderColor="gray.200"
      borderRadius="md"
      p={4}
      maxHeight="500px" // Increased height to accommodate search
      overflowY="auto"
    >
      {/* Search Input */}
      <Flex mb={4} align="center">
        <Input
          placeholder="Search categories..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          size="sm"
          mr={2}
        />
        <Icon as={SearchIcon} color="gray.500" />
      </Flex>

      {/* Breadcrumbs and Expand/Collapse Buttons */}
      {selectedCategoryUuid && (
        <>
          <Flex justify="space-between" align="center" mb={4}>
            <Breadcrumb separator=">">
              {findCategoryPath(categories, selectedCategoryUuid)?.map(
                (cat, idx, array) => (
                  <BreadcrumbItem key={cat.value}>
                    <BreadcrumbLink
                      onClick={() => onSelectCategory(cat.value)}
                      cursor="pointer"
                      color={idx === array.length - 1 ? 'blue.600' : 'gray.600'}
                    >
                      {cat.label}
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                ),
              )}
            </Breadcrumb>
            <Button
              size="sm"
              variant="outline"
              onClick={isAllExpanded ? handleCollapseAll : handleExpandAll}
            >
              {isAllExpanded ? 'Collapse All' : 'Expand All'}
            </Button>
          </Flex>
          <Divider my={4} />
        </>
      )}

      {/* Category Tree */}
      {renderCategories(filteredCategories)}
    </Box>
  );
};

CategoryTreeSelect.propTypes = {
  categories: PropTypes.array.isRequired,
  selectedCategoryUuid: PropTypes.string,
  onSelectCategory: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
};

CategoryTreeSelect.defaultProps = {
  selectedCategoryUuid: '',
};

export default CategoryTreeSelect;
