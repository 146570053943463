export const transformCategories = (categories, defaultLang = 'en') => {
  return categories.map((category) => ({
    ...category,
    name:
      category.translations?.[defaultLang]?.category_name || 'Unnamed Category',
    child_categories: category.child_categories
      ? transformCategories(category.child_categories, defaultLang)
      : [],
  }));
};

export const flattenCategoryTree = (tree) => {
  const flat = [];

  const traverse = (nodes, parentId = null) => {
    nodes.forEach((node) => {
      flat.push({
        ...node,
        parent_id: parentId,
      });
      if (node.child_categories && node.child_categories.length > 0) {
        traverse(node.child_categories, node.id);
      }
    });
  };

  traverse(tree);
  return flat;
};

export const flattenCategories = (
  categories,
  language = 'en',
  depth = 0,
  parentName = '',
) => {
  let flatList = [];
  categories.forEach((category) => {
    const name =
      category.translations[language]?.category_name || 'Unnamed Category';
    const fullName = parentName ? `${parentName} > ${name}` : name;
    flatList.push({ ...category, displayName: fullName });
    if (category.child_categories && category.child_categories.length > 0) {
      flatList = flatList.concat(
        flattenCategories(
          category.child_categories,
          language,
          depth + 1,
          fullName,
        ),
      );
    }
  });
  return flatList;
};

export const transformCategoriesForTreeSelect = (
  categories,
  language = 'en',
) => {
  return categories.map((category) => ({
    label: category.translations[language]?.category_name || 'Unnamed Category',
    value: category.uuid,
    totalCount: category.totalCount !== undefined ? category.totalCount : 0,
    children: category.child_categories
      ? transformCategoriesForTreeSelect(category.child_categories, language)
      : [],
  }));
};

export const pruneCategoryTree = (categories, allowedCategoryUuids) => {
  const prune = (categoriesList) => {
    return categoriesList.reduce((acc, category) => {
      let children = [];
      if (category.children && category.children.length > 0) {
        children = prune(category.children);
      }
      if (allowedCategoryUuids.has(category.uuid) || children.length > 0) {
        acc.push({
          ...category,
          children,
        });
      }
      return acc;
    }, []);
  };

  return prune(categories);
};

export const filterCategoriesWithProducts = (categories, categoryUuids) => {
  return categories.reduce((filtered, category) => {
    let hasProduct = categoryUuids.includes(category.uuid);

    let children = [];

    // Use category.children instead of category.child_categories
    if (category.child_categories && category.child_categories.length > 0) {
      children = filterCategoriesWithProducts(category.children, categoryUuids);

      if (children.length > 0) {
        hasProduct = true;
      }
    }

    if (hasProduct) {
      filtered.push({ ...category, children });
    }

    return filtered;
  }, []);
};

export const nestProductsWithinCategories = (categories, products) => {
  return categories.map((category) => {
    const associatedProducts = products.filter(
      (product) => product.category_uuid === category.uuid,
    );

    const nestedChildren = category.child_categories
      ? nestProductsWithinCategories(category.child_categories, products)
      : [];

    return {
      ...category,
      products: associatedProducts,
      child_categories: nestedChildren,
    };
  });
};

export const addProductCounts = (categories, categoryProductMap) => {
  return categories.map((cat) => {
    let productCount = categoryProductMap[cat.value] || 0; // Use cat.value instead of cat.uuid
    let children = [];
    if (cat.children && cat.children.length > 0) {
      children = addProductCounts(cat.children, categoryProductMap);
      productCount += children.reduce(
        (sum, child) => sum + child.totalCount,
        0,
      );
    }
    return { ...cat, productCount, totalCount: productCount, children };
  });
};

// export const addProductCounts = (categories, categoryProductMap) => {
//   return categories.map(category => {
//     let productCount = categoryProductMap[category.value] || 0;
//     let children = [];

//     if (category.children && category.children.length > 0) {
//       children = addProductCounts(category.children, categoryProductMap);
//       productCount += children.reduce((sum, child) => sum + child.totalCount, 0);
//     }

//     return { ...category, totalCount: productCount, children };
//   });
// };
