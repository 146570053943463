import apiClient from './axios';

export const getLoyaltyPrograms = async () => {
  try {
    const response = await apiClient.get('/loyalty-programs', {});
    return response.data;
  } catch (error) {
    console.error('Error fetching loyalty programs:', error);
    throw error;
  }
};

export const getLoyaltyLevels = async () => {
  const response = await apiClient.get('/loyalty-levels/');
  return response.data;
};

export const updateLoyaltyProgram = async (uuid, data) => {
  try {
    const response = await apiClient.put(`/loyalty-programs/${uuid}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating loyalty program:', error);
    throw error;
  }
};

export const updateLoyaltyLevel = async (uuid, data) => {
  try {
    const response = await apiClient.put(`/loyalty-levels/${uuid}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating loyalty level:', error);
    throw error;
  }
};

export const updateProduct = async (uuid, data) => {
  try {
    const response = await apiClient.put(`/products/${uuid}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating product:', error);
    throw error;
  }
};

export const createLoyaltyProgram = async (data) => {
  try {
    const response = await apiClient.post('/loyalty-programs', data);
    return response.data;
  } catch (error) {
    console.error('Error creating loyalty program:', error);
    throw error;
  }
};

export const createLoyaltyLevel = async (data) => {
  try {
    const response = await apiClient.post('/loyalty-levels', data);
    return response.data;
  } catch (error) {
    console.error('Error creating loyalty level:', error);
    throw error;
  }
};

export const createProduct = async (data) => {
  try {
    const response = await apiClient.post('/products', data);
    return response.data;
  } catch (error) {
    console.error('Error creating product:', error);
    throw error;
  }
};

export const deleteLoyaltyProgram = async (uuid) => {
  try {
    const response = await apiClient.delete(`/loyalty-programs/${uuid}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting loyalty program:', error);
    throw error;
  }
};

export const addLoyaltyLevelArt = async (uuid, formData) => {
  try {
    if (!formData) {
      throw new Error('No data provided');
    }
    console.log('Raw formData:', Object.fromEntries(formData));
    const response = await apiClient.post(
      `/loyalty-levels/${uuid}/image`,
      formData,
    );
    return response.data;
  } catch (error) {
    console.error('Error adding loyalty level art:', error);
    throw error;
  }
};

export const getAllLoyaltyLevelArt = async (uuid) => {
  try {
    const response = await apiClient.get(`/loyalty-levels/${uuid}/images`);
    return response.data;
  } catch (error) {
    console.error('Error fetching loyalty level art:', error);
    throw error;
  }
};

export const deleteLoyaltyLevelArt = async (levelUuid, imageUuid) => {
  try {
    const response = await apiClient.delete(
      `/loyalty-levels/${levelUuid}/images/${imageUuid}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting loyalty level art:', error);
    throw error;
  }
};

export const updateLoyaltyLevelArt = async (
  levelUuid,
  imageUuid,
  occurrence_percentage,
  image = null,
) => {
  try {
    const formData = new FormData();

    if (image) {
      formData.append('file', image);
    }

    if (occurrence_percentage !== undefined) {
      formData.append('occurrence_percentage', occurrence_percentage);
    }

    const response = await apiClient.put(
      `/loyalty-levels/${levelUuid}/images/${imageUuid}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error updating loyalty level art:', error);
    throw error;
  }
};
