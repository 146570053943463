/**
 * Sorts products by availability and then alphabetically by name.
 * @param {Array} products - The list of products to sort.
 * @returns {Array} - The sorted list of products.
 */
export const sortProductsByAvailability = (products) => {
  return [...products].sort((a, b) => {
    const aIsSoldOut = a.is_sold_out || a.quantity === 0 || a.quantity === null;
    const bIsSoldOut = b.is_sold_out || b.quantity === 0 || b.quantity === null;

    if (aIsSoldOut !== bIsSoldOut) {
      return Number(aIsSoldOut) - Number(bIsSoldOut);
    }

    return a.name.localeCompare(b.name);
  });
};
