import { Box, Icon, Text, useColorModeValue, Input } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import React from 'react';
import { MdOutlineCloudUpload } from 'react-icons/md';

const DropzoneLoyalty = ({
  onDrop,
  height = '200px',
  width = '100%',
  iconSize = '40px',
  ...rest
}) => {
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false // This prevents multiple file selection in the file dialog
  });
  const bg = useColorModeValue('gray.100', 'navy.700');
  const borderColor = useColorModeValue('gray.300', 'whiteAlpha.100');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const brand = useColorModeValue('brand.500', 'brand.400');

  // Calculate dynamic sizes based on height
  const isSmall = parseInt(height) <= 100;
  const calculatedIconSize = isSmall ? '16px' : iconSize;
  const primaryFontSize = isSmall ? 'xs' : 'sm';
  const secondaryFontSize = isSmall ? '10px' : 'sm';
  const spacing = isSmall ? '4px' : '8px';

  return (
    <Box
      {...rest}
      {...getRootProps()}
      h={height}
      w={width}
      bgColor={bg}
      border="1px dashed"
      borderColor={fileRejections.length > 0 ? 'red.500' : borderColor}
      borderRadius="16px"
      maxW="100%"
      cursor="pointer"
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={2}
    >
      <Input variant="main" name="file" {...getInputProps()} accept="image/png,image/jpeg,image/gif,image/webp" />
      <Box maxW="100%" textAlign="center">
        <Icon
          as={MdOutlineCloudUpload}
          w={calculatedIconSize}
          h={calculatedIconSize}
          color={textColor}
        />
        <Text
          mb={spacing}
          fontSize={primaryFontSize}
          w="100%"
          maxW="100%"
          fontWeight="700"
          color={textColor}
          whiteSpace="pre-wrap"
        >
          Drop your image here, or{' '}
          <Text
            as="span"
            fontSize={primaryFontSize}
            fontWeight="700"
            color={brand}
          >
            click to browse
          </Text>
        </Text>
        <Text
          fontSize={secondaryFontSize}
          fontWeight="500"
          color="secondaryGray.500"
          whiteSpace="pre-wrap !important"
        >
          PNG, JPG, GIF, and WEBP files are allowed
        </Text>
      </Box>
    </Box>
  );
};

export default DropzoneLoyalty;
