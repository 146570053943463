import {
  Flex,
  Grid,
  useColorModeValue,
  Input,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  HStack,
  Select,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
// Custom components
import SalesWidget from 'components/dashboard/OverallRevenueWidget';
import UsersOverviewWidget from 'components/dashboard/UsersOverviewWidget';
import LoyaltyProgramsWidget from 'components/dashboard/LoyaltyProgramWidget';
import LatestLoyaltyPrograms from 'components/dashboard/LatestLoyaltyProgramsWidget';
import LatestProducts from 'components/dashboard/LatestProductsWidget';
import CreditBalanceWidget from 'components/dashboard/CreditBalanceWidget';
import { VSeparator } from 'components/separator/Separator';
import { subDays, subMonths, subYears, format, parseISO } from 'date-fns';

export default function Default() {
  // Chakra Color Mode
  const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');

  const [startDate, setStartDate] = useState(
    format(subYears(new Date(), 1), 'yyyy-MM-dd'),
  );
  const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [dateRange, setDateRange] = useState('lastYear');
  const [groupBy, setGroupBy] = useState('month');

  const [allowedGroupByOptions, setAllowedGroupByOptions] = useState([
    'day',
    'week',
    'month',
    'year',
  ]);

  useEffect(() => {
    const calculateAllowedGroupBy = () => {
      if (startDate && endDate) {
        const start = parseISO(startDate);
        const end = parseISO(endDate);
        const diffTime = end - start;
        const diffDays = diffTime / (1000 * 60 * 60 * 24);

        let options = [];
        if (diffDays <= 7) {
          options.push('day');
        } else if (diffDays <= 31) {
          options.push('day', 'week');
        } else if (diffDays <= 365) {
          options.push('day', 'week', 'month');
        } else {
          options.push('day', 'week', 'month', 'year');
        }

        setAllowedGroupByOptions(options);

        if (!options.includes(groupBy)) {
          setGroupBy(options[0]);
        }
      }
    };

    calculateAllowedGroupBy();
  }, [startDate, endDate, groupBy]);

  useEffect(() => {
    const today = new Date();
    switch (dateRange) {
      case 'last7days':
        setStartDate(format(subDays(today, 7), 'yyyy-MM-dd'));
        setEndDate(format(today, 'yyyy-MM-dd'));
        break;
      case 'lastMonth':
        setStartDate(format(subMonths(today, 1), 'yyyy-MM-dd'));
        setEndDate(format(today, 'yyyy-MM-dd'));
        break;
      case 'lastYear':
        setStartDate(format(subYears(today, 1), 'yyyy-MM-dd'));
        setEndDate(format(today, 'yyyy-MM-dd'));
        break;
      case 'custom':
        // Do nothing, custom dates will be set manually
        break;
      default:
        setStartDate('');
        setEndDate('');
    }
  }, [dateRange]);

  return (
    <Flex direction="column" pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex mb="20px" alignItems="center">
        <RadioGroup
          onChange={setDateRange}
          value={dateRange}
          defaultValue="lastYear"
        >
          <HStack spacing="10px" whiteSpace="nowrap">
            <Radio value="last7days">Last 7 Days</Radio>
            <Radio value="lastMonth">Last Month</Radio>
            <Radio value="lastYear">Last Year</Radio>
            <Radio value="custom">Custom</Radio>
          </HStack>
        </RadioGroup>

        <Flex pb="20px" ml="20px">
          <FormControl mr="10px">
            <FormLabel fontSize="xs">Start Date</FormLabel>
            <Input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              disabled={dateRange !== 'custom'}
              size="sm"
              borderRadius="md"
            />
          </FormControl>
          <FormControl mr="10px">
            <FormLabel fontSize="xs">End Date</FormLabel>
            <Input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              disabled={dateRange !== 'custom'}
              size="sm"
              borderRadius="md"
            />
          </FormControl>
        </Flex>

        {/* Group By Control */}
        <FormControl pb="20px" ml="90px" w="auto">
          <FormLabel fontSize="xs">Group By</FormLabel>
          <Select
            value={groupBy}
            onChange={(e) => setGroupBy(e.target.value)}
            size="sm"
            borderRadius="md"
            width="auto"
          >
            {allowedGroupByOptions.map((option) => (
              <option key={option} value={option}>
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </option>
            ))}
          </Select>
        </FormControl>
      </Flex>

      <Grid
        templateColumns={{
          base: '1fr', // Single column on small screens
          md: 'repeat(2, 1fr)', // Two columns on medium screens
          xl: '5fr auto 3fr', // Original 3-column layout on extra large
        }}
        gap="20px"
      >
        {/* Left Column */}
        <Flex
          direction="column"
          gap="20px"
          gridColumn={{ base: '1 / -1', md: '1 / 3', xl: '1 / 2' }}
          flexWrap="wrap"
        >
          <SalesWidget
            startDate={startDate}
            endDate={endDate}
            groupBy={groupBy}
          />
          <UsersOverviewWidget
            startDate={startDate}
            endDate={endDate}
            groupBy={groupBy}
          />
          <LoyaltyProgramsWidget
            startDate={startDate}
            endDate={endDate}
            groupBy={groupBy}
          />
        </Flex>

        {/* Vertical Separator (hidden on base/md) */}
        <Flex
          display={{ base: 'none', md: 'none', xl: 'flex' }}
          justifyContent="center"
          gridColumn="2 / 3"
        >
          <VSeparator h="100%" bg={paleGray} />
        </Flex>

        {/* Right Column */}
        <Flex
          direction="column"
          gap="20px"
          gridColumn={{ base: '1 / -1', md: '1 / -1', xl: '3 / 4' }}
        >
          <CreditBalanceWidget />
          <LatestLoyaltyPrograms />
          <LatestProducts />
        </Flex>
      </Grid>
    </Flex>
  );
}
