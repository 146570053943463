import React, { useState, useEffect } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Text,
  Flex,
  Input,
  Image,
  Button,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  Checkbox,
  Select,
  HStack,
  VStack,
  Switch,
  useToast,
  Spinner,
  Textarea,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  IconButton,
  SimpleGrid,
  useColorModeValue,
  Tooltip,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { StarIcon, DeleteIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import Card from 'components/card/Card.js';
import DropzoneLoyalty from 'components/loyalty/LoyaltyDropzone';
import LoyaltyImageModal from 'components/loyalty/LoyaltyImageModal';

import {
  handleImageUpload,
  handleSetFeaturedImage,
  handleDeleteImage,
  getProductDetails,
} from 'api/product';
import {
  getLoyaltyLevels,
  updateLoyaltyLevel,
  updateProduct,
} from 'api/loyalty';
import { convertToDays } from 'utils/loyaltyUtils';
import LoyaltyLevelModalAlert from 'components/loyalty/LoyaltyLevelModalAlert';
import { setTranslation } from 'utils/translationUtils';
import { getAllLoyaltyLevelArt } from 'api/loyalty';
import { addLoyaltyLevelArt } from 'api/loyalty';

const TIME_UNITS = [
  {
    days: 365,
    name: 'years',
  },
  {
    days: 30,
    name: 'months',
  },
  {
    days: 7,
    name: 'weeks',
  },
  {
    days: 1,
    name: 'days',
  },
];

const convertDaysToOptimalUnit = (days) => {
  if (!days) return { value: 0, unit: 'days' };

  for (const unit of TIME_UNITS) {
    if (days >= unit.days && days % unit.days === 0) {
      return {
        value: days / unit.days,
        unit: unit.name,
      };
    }
  }

  return { value: days, unit: 'days' };
};

export default function LoyaltyLevelDetail() {
  const { levelUuid } = useParams();
  const toast = useToast();
  const [isUploading, setIsUploading] = useState(false);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const cancelRef = React.useRef();
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';

  const [isLoading, setIsLoading] = useState(true);
  const [languages] = useState([
    { code: 'en', name: 'English' },
    { code: 'et', name: 'Estonian' },
  ]);
  const [artImages, setArtImages] = useState([]);
  const [isArtUploading, setIsArtUploading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const [levelData, setLevelData] = useState({
    product_uuid: '',
    loyalty_id: '',
    translations: languages.flatMap((lang) => [
      { language: lang.code, key: 'name', value: '' },
      { language: lang.code, key: 'description', value: '' },
    ]),
    price: 0,
    expirationTimePeriod: 0,
    expirationTimeUnit: 'days',
    is_archived: false,
    is_active: true,
    is_art_random: false,
    is_art_unique: false,
    quantity: 0,
    sold_quantity: 0,
    is_infinite: false,
    has_expiration: false,
    images: [],
  });

  useEffect(() => {
    const fetchLevelData = async () => {
      setIsLoading(true);
      try {
        const levels = await getLoyaltyLevels();
        const level = levels.find((level) => level.uuid === levelUuid);
        if (level) {
          const productResponse = await getProductDetails(level.product_uuid);

          try {
            const loyaltyArt = await getAllLoyaltyLevelArt(levelUuid);
            setArtImages(Array.isArray(loyaltyArt) ? loyaltyArt : []);
          } catch (error) {
            console.error('Error fetching art images:', error);
            setArtImages([]);
          }

          const { value, unit } = convertDaysToOptimalUnit(
            level.expiration_period,
          );

          const translationsArray = languages.flatMap((language) => [
            {
              language: language.code,
              key: 'name',
              value: '',
            },
            {
              language: language.code,
              key: 'description',
              value: '',
            },
          ]);

          // Update translations with actual values if they exist
          if (level.translations && level.translations.length > 0) {
            level.translations.forEach((translation) => {
              const index = translationsArray.findIndex(
                (t) =>
                  t.language === translation.language &&
                  t.key === translation.key,
              );
              if (index !== -1) {
                translationsArray[index].value = translation.value;
              }
            });
          }

          setLevelData({
            product_uuid: level.product_uuid,
            loyalty_id: level.loyalty_id,
            translations: translationsArray,
            price: level.price,
            expirationTimePeriod: value,
            expirationTimeUnit: unit,
            is_archived: level.is_archived,
            is_active: level.is_active,
            is_art_random: level.is_art_random,
            is_art_unique: level.is_art_unique,
            quantity: level.quantity,
            sold_quantity: level.sold_quantity,
            is_infinite: !level.quantity,
            has_expiration: !!level.expiration_period,
            images:
              productResponse?.images?.map((img) => ({
                uuid: img.uuid,
                image_url: img.image_url,
                thumb_url: img.thumb_url,
                is_featured: img.is_featured,
              })) || [],
          });
        }
      } catch (error) {
        console.error('Error fetching level data:', error);
        toast({
          title: 'Error',
          description: 'Failed to load loyalty level data',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchLevelData();
  }, [levelUuid, languages, toast]);

  useEffect(() => {
    if (selectedImage && isImageModalOpen) {
      const updatedImage = artImages.find(
        (img) => img.uuid === selectedImage.uuid,
      );
      if (updatedImage) {
        setSelectedImage(updatedImage);
      }
    }
  }, [artImages, selectedImage, isImageModalOpen]);

  const getTranslationValue = (translations, language, key) => {
    if (!Array.isArray(translations)) {
      // Convert object format to array format if needed
      if (typeof translations === 'object') {
        return translations[language]?.[key] || '';
      }
      return '';
    }
    return (
      translations.find((t) => t.language === language && t.key === key)
        ?.value || ''
    );
  };

  const handleSave = async () => {
    if (!levelData.translations || levelData.translations.length === 0) {
      toast({
        title: 'Error',
        description:
          'Please fill in the name and description for at least one language',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!levelData.price || levelData.price <= 0) {
      toast({
        title: 'Error',
        description: 'Please enter a valid price',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (
      !levelData.is_infinite &&
      (!levelData.quantity || levelData.quantity <= 0)
    ) {
      toast({
        title: 'Error',
        description: 'Please enter a valid quantity',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (
      levelData.has_expiration &&
      (!levelData.expirationTimePeriod || levelData.expirationTimePeriod <= 0)
    ) {
      toast({
        title: 'Error',
        description: 'Please enter a valid expiration time period',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const days = convertToDays(
        levelData.expirationTimePeriod,
        levelData.expirationTimeUnit,
      );

      await updateLoyaltyLevel(levelUuid, {
        expiration_period: levelData.has_expiration ? days : 0,
        is_active: levelData.is_active,
        is_archived: levelData.is_archived,
      });

      await updateProduct(levelData.product_uuid, {
        price: levelData.price,
        quantity: levelData.is_infinite ? 0 : levelData.quantity,
        status: levelData.is_active ? 'active' : 'inactive',
        is_archived: levelData.is_archived,
      });

      for (const translation of levelData.translations) {
        if (translation.key === 'name' || translation.key === 'description') {
          await setTranslation(
            `product_${translation.key}_${levelData.product_uuid}`,
            translation.value,
            translation.language,
          );
        }
      }

      toast({
        title: 'Success',
        description: 'Loyalty level updated successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating loyalty level:', error);
      toast({
        title: 'Error',
        description: 'Failed to update loyalty level',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleImageUploadWrapper = (files) => {
    setIsUploading(true);
    handleImageUpload(files, levelData.product_uuid)
      .then((response) => {
        setTimeout(() => {
          setLevelData((prevData) => ({
            ...prevData,
            images: [...prevData.images, response],
          }));
          setIsUploading(false);
        }, 2000);
      })
      .catch((error) => {
        console.error('Error uploading image:', error);
        toast({
          title: 'Error',
          description: 'Failed to upload image',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsUploading(false);
      });
  };

  const handleSetFeaturedImageWrapper = (imageUuid) => {
    handleSetFeaturedImage(levelData.product_uuid, imageUuid)
      .then(() => {
        setLevelData((prevData) => ({
          ...prevData,
          images: prevData.images.map((image) => ({
            ...image,
            is_featured: image.uuid === imageUuid,
          })),
        }));
      })
      .catch((error) => {
        console.error('Error setting featured image:', error);
        toast({
          title: 'Error',
          description: 'Failed to set featured image',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleDeleteImageWrapper = (imageUuid) => {
    handleDeleteImage(levelData.product_uuid, imageUuid)
      .then(() => {
        setLevelData((prevData) => ({
          ...prevData,
          images: prevData.images.filter((image) => image.uuid !== imageUuid),
        }));
      })
      .catch((error) => {
        console.error('Error deleting image:', error);
        toast({
          title: 'Error',
          description: 'Failed to delete image',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleArtImageUpload = async (files) => {
    setIsArtUploading(true);

    const tempUrl = URL.createObjectURL(files[0]);
    const tempImage = {
      uuid: 'temp-' + Date.now(),
      image_url: tempUrl,
      occurrence_percentage: 0,
      isTemp: true,
    };

    setArtImages((prevImages) => [...prevImages, tempImage]);

    const formData = new FormData();
    formData.append('occurrence_percentage', 0);
    formData.append('file', files[0]);

    try {
      const response = await addLoyaltyLevelArt(levelUuid, formData);
      // Replace temporary image with actual response
      setArtImages((prevImages) =>
        prevImages.map((img) => (img.uuid === tempImage.uuid ? response : img)),
      );

      // If modal is open with the same level, update its state too
      if (isImageModalOpen) {
        setSelectedImage(response);
      }

      toast({
        title: 'Success',
        description: 'Art image uploaded successfully',
        status: 'success',
        duration: 3000,
      });
    } catch (error) {
      console.error(`Error uploading art image: ${error}`);
      toast({
        title: 'Error',
        description: 'Failed to upload art image',
        status: 'error',
        duration: 3000,
      });
    } finally {
      URL.revokeObjectURL(tempUrl);
      setIsArtUploading(false);
    }
  };

  const handleTranslationChange = (language, key, value) => {
    setLevelData((prev) => {
      const newTranslations = [...prev.translations];
      const translationIndex = newTranslations.findIndex(
        (translation) =>
          translation.language === language && translation.key === key,
      );

      if (translationIndex !== -1) {
        newTranslations[translationIndex].value = value;
      } else {
        newTranslations.push({
          language,
          key,
          value,
        });
      }

      return {
        ...prev,
        translations: newTranslations,
      };
    });
  };

  const handleArchive = async () => {
    try {
      await updateLoyaltyLevel(levelUuid, {
        is_archived: !levelData.is_archived,
      });
      setLevelData((prevData) => ({
        ...prevData,
        is_archived: !prevData.is_archived,
      }));
      setIsArchiveDialogOpen(false);
    } catch (error) {
      console.error('Error updating loyalty level:', error);
      toast({
        title: 'Error',
        description: 'Failed to update loyalty level',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleArtImageUpdate = (updatedImage) => {
    setArtImages((prevImages) =>
      prevImages.map((img) =>
        img.uuid === updatedImage.uuid
          ? {
              ...img,
              ...updatedImage,
              image_url: updatedImage.image_url,
            }
          : img,
      ),
    );

    if (selectedImage?.uuid === updatedImage.uuid) {
      setSelectedImage(updatedImage);
    }
  };

  const handleArtImageDelete = (imageUuid) => {
    setArtImages((prevImages) =>
      prevImages.filter((img) => img.uuid !== imageUuid),
    );
  };

  const calculateTotalOccurrence = (images) => {
    return images.reduce(
      (sum, image) => sum + (image.occurrence_percentage || 0),
      0,
    );
  };

  if (isLoading) {
    return (
      <Flex justify="center" align="center" h="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <FormControl pt={{ sm: '125px', md: '75px' }}>
      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="20px">
        <Card>
          <Flex direction="column" mb="40px" ms="10px">
            <Flex justify="space-between" align="center">
              <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                Level Information
              </Text>
              <Flex align="center">
                <Text
                  fontSize="xl"
                  color={textColorPrimary}
                  mr="10px"
                  fontWeight="bold"
                >
                  Active Status
                </Text>
                <Switch
                  isChecked={levelData.is_active}
                  onChange={(e) =>
                    setLevelData({
                      ...levelData,
                      is_active: e.target.checked,
                    })
                  }
                  colorScheme="blue"
                  size="lg"
                />
              </Flex>
            </Flex>
            <Text fontSize="md" color={textColorSecondary}>
              Edit the details of your loyalty level
            </Text>
          </Flex>

          {/* Main form content */}
          <Flex direction="column">
            <Tabs mx="-15px">
              <TabList>
                {languages?.map((language) => (
                  <Tab key={language.code}>
                    {language.code === 'en' ? 'English' : 'Estonian'}
                  </Tab>
                ))}
              </TabList>
              <TabPanels>
                {languages?.map((lang) => (
                  <TabPanel key={lang.code}>
                    <FormLabel>Name</FormLabel>
                    <Input
                      mb="15px"
                      placeholder="Name"
                      value={getTranslationValue(
                        levelData.translations,
                        lang.code,
                        'name',
                      )}
                      onChange={(e) =>
                        handleTranslationChange(
                          lang.code,
                          'name',
                          e.target.value,
                        )
                      }
                      required
                    />
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      mb="15px"
                      placeholder="Description"
                      value={getTranslationValue(
                        levelData.translations,
                        lang.code,
                        'description',
                      )}
                      onChange={(e) =>
                        handleTranslationChange(
                          lang.code,
                          'description',
                          e.target.value,
                        )
                      }
                      required
                    />
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>

            <FormLabel htmlFor="price">Price</FormLabel>
            <NumberInput
              w="25%"
              mb="15px"
              id="price"
              defaultValue={levelData.price}
              min={0}
              precision={2}
              step={0.1}
              required
              allowMouseWheel
              onChange={(value) =>
                setLevelData({
                  ...levelData,
                  price: value,
                })
              }
            >
              <NumberInputField placeholder="Price" />
            </NumberInput>

            <FormLabel htmlFor="quantity">Quantity</FormLabel>
            <Flex mb="15px" align="center">
              <NumberInput
                w="25%"
                disabled={levelData.is_infinite}
                id="quantity"
                placeholder="Quantity"
                value={levelData.quantity ?? 0}
                allowMouseWheel
                precision={0}
                step={1}
                min={levelData.sold_quantity || 0}
                onChange={(value) =>
                  setLevelData({
                    ...levelData,
                    quantity: parseInt(value, 10) || 0,
                  })
                }
              >
                <NumberInputField placeholder="Quantity" />
              </NumberInput>
              <Checkbox
                ml="10px"
                isChecked={levelData.is_infinite}
                onChange={(e) =>
                  setLevelData({
                    ...levelData,
                    is_infinite: e.target.checked,
                    quantity: 0,
                  })
                }
              >
                Unlimited Quantity
              </Checkbox>
            </Flex>
            {levelData.sold_quantity > 0 && (
              <Text mb="10px" color="gray.500" fontSize="sm">
                Sold Quantity: {levelData.sold_quantity}
              </Text>
            )}

            <FormControl mb="15px">
              <FormLabel htmlFor="expirationTimePeriod">
                Expiration Time
              </FormLabel>
              <HStack>
                <Input
                  id="expirationTimePeriod"
                  placeholder="Period"
                  value={levelData.expirationTimePeriod}
                  onChange={(e) =>
                    setLevelData({
                      ...levelData,
                      expirationTimePeriod: e.target.value,
                    })
                  }
                  type="number"
                  w="25%"
                  disabled={!levelData.has_expiration}
                />
                <Select
                  id="expirationTimeUnit"
                  placeholder="Select Time Unit"
                  value={levelData.expirationTimeUnit}
                  disabled={!levelData.has_expiration}
                  onChange={(e) =>
                    setLevelData({
                      ...levelData,
                      expirationTimeUnit: e.target.value,
                    })
                  }
                  w="25%"
                >
                  <option value="days">Days</option>
                  <option value="weeks">Weeks</option>
                  <option value="months">Months</option>
                  <option value="years">Years</option>
                </Select>
                <Checkbox
                  isChecked={levelData.has_expiration}
                  onChange={(e) =>
                    setLevelData({
                      ...levelData,
                      has_expiration: e.target.checked,
                    })
                  }
                >
                  Expires?
                </Checkbox>
              </HStack>
            </FormControl>
            {/* Footer buttons */}
            <Flex mt="40px" justify="space-between">
              <Button
                colorScheme={levelData.is_archived ? 'blue' : 'red'}
                onClick={() => setIsArchiveDialogOpen(true)}
                w="150px"
              >
                {levelData.is_archived ? 'Unarchive' : 'Archive'}
              </Button>
              <HStack spacing={4}>
                <Button colorScheme="blue" onClick={handleSave} w="200px">
                  Save Changes
                </Button>
              </HStack>
            </Flex>
          </Flex>
        </Card>

        <VStack spacing="20px" align="stretch">
          {/* Images Card */}
          <Card>
            <Flex justify="space-between" align="flex-start" ms="10px">
              <Box>
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                  Level Images
                </Text>
                <Text fontSize="md" color={textColorSecondary}>
                  Manage the images for this loyalty level
                </Text>
              </Box>

              <Box>
                <DropzoneLoyalty
                  onDrop={handleImageUploadWrapper}
                  height="120px"
                  width="300px"
                  iconSize="30px"
                />
              </Box>
            </Flex>

            <Box mt="10px">
              <SimpleGrid columns={3} spacing={4} mt={4}>
                {levelData?.images?.map((image) => (
                  <Box key={image.uuid} position="relative" w="80%">
                    <Image
                      src={image.thumb_url}
                      alt="Level image"
                      borderRadius="md"
                      objectFit="cover"
                    />
                    <IconButton
                      icon={
                        image.is_featured ? (
                          <StarIcon color="yellow.500" />
                        ) : (
                          <StarIcon />
                        )
                      }
                      position="absolute"
                      top={2}
                      right={2}
                      size="sm"
                      onClick={() => handleSetFeaturedImageWrapper(image.uuid)}
                    />
                    <IconButton
                      icon={<DeleteIcon />}
                      position="absolute"
                      top={2}
                      left={2}
                      size="sm"
                      onClick={() => handleDeleteImageWrapper(image.uuid)}
                    />
                  </Box>
                ))}
              </SimpleGrid>
              {isUploading && (
                <Flex justify="center" mt="20px">
                  <Spinner />
                </Flex>
              )}
            </Box>
          </Card>

          {/* Art Images Card */}
          <Card>
            <Flex
              justify="space-between"
              align="flex-start"
              mb="20px"
              ms="10px"
            >
              <Box>
                <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                  Level Art Images
                </Text>
                <Text fontSize="md" color={textColorSecondary}>
                  Manage the art images for this loyalty level
                </Text>
                <VStack align="flex-start" mt="10px">
                  <HStack>
                    <Checkbox
                      isChecked={levelData.is_art_random}
                      onChange={(e) =>
                        setLevelData({
                          ...levelData,
                          is_art_random: e.target.checked,
                          is_art_unique: false,
                        })
                      }
                    >
                      Random Distribution
                    </Checkbox>
                    <Tooltip
                      label="Images will be randomly distributed to users when checked. By default, the images are ordered by upload date."
                      fontSize="xs"
                      placement="right"
                    >
                      <InfoOutlineIcon
                        boxSize={4}
                        color="gray.500"
                        cursor="help"
                      />
                    </Tooltip>
                  </HStack>
                  <HStack>
                    <Checkbox
                      isChecked={levelData.is_art_unique}
                      onChange={(e) =>
                        setLevelData({
                          ...levelData,
                          is_art_unique: e.target.checked,
                          is_art_random: false,
                        })
                      }
                    >
                      Unique Distribution
                    </Checkbox>
                    <Tooltip
                      label="Each image will be used only once when checked. By default, images can be assigned to multiple cards"
                      fontSize="xs"
                      placement="right"
                    >
                      <InfoOutlineIcon
                        boxSize={4}
                        color="gray.500"
                        cursor="help"
                      />
                    </Tooltip>
                  </HStack>
                </VStack>

                {calculateTotalOccurrence(artImages) > 100 && (
                  <Alert status="warning" mt={4} borderRadius="md">
                    <AlertIcon />
                    <Text fontSize="sm">
                      Total occurrence percentage (
                      {calculateTotalOccurrence(artImages)}%) exceeds 100%.
                      Adjust to avoid calculation errors
                    </Text>
                  </Alert>
                )}
              </Box>

              <Box>
                <DropzoneLoyalty
                  onDrop={handleArtImageUpload}
                  height="120px"
                  width="300px"
                  iconSize="30px"
                />
              </Box>
            </Flex>

            <Box mt="20px">
              <SimpleGrid columns={3} spacing={4} mt={4}>
                {artImages &&
                  artImages.map((image) => (
                    <Box
                      key={image.uuid}
                      position="relative"
                      cursor="pointer"
                      onClick={() => {
                        setSelectedImage(image);
                        setIsImageModalOpen(true);
                      }}
                    >
                      <Image
                        src={image.image_url}
                        alt="Level art image"
                        borderRadius="md"
                        objectFit="cover"
                      />
                    </Box>
                  ))}
              </SimpleGrid>
              {isArtUploading && (
                <Flex justify="center" mt="20px">
                  <Spinner />
                </Flex>
              )}
            </Box>
          </Card>
        </VStack>
      </SimpleGrid>

      <LoyaltyLevelModalAlert
        isArchiveDialogOpen={isArchiveDialogOpen}
        setIsArchiveDialogOpen={setIsArchiveDialogOpen}
        levelModalData={levelData}
        handleArchive={handleArchive}
        cancelRef={cancelRef}
      />

      <LoyaltyImageModal
        isOpen={isImageModalOpen}
        onClose={() => {
          setIsImageModalOpen(false);
          setSelectedImage(null);
        }}
        image={selectedImage}
        setSelectedImage={setSelectedImage}
        levelUuid={levelUuid}
        onImageUpdate={handleArtImageUpdate}
        onImageDelete={handleArtImageDelete}
      />
    </FormControl>
  );
}
