// ProductOverview.js
import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import {
  Flex,
  Spinner,
  Text,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Badge,
  Switch,
  FormControl,
  FormLabel,
  Card,
  Icon,
  HStack,
  useToast,
  Tooltip,
  Box,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { AddIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { MdEdit } from 'react-icons/md';
import { RiArchiveFill, RiInboxUnarchiveFill } from 'react-icons/ri';
import { FiFilter } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import { getProducts, updateProduct } from '../../api/product';
import { getCategories } from '../../api/category';
import Pagination from '../../components/pagination';
import ProductArchiveAlert from '../../components/products/ProductArchiveAlert';
import {
  flattenCategories,
  transformCategoriesForTreeSelect,
  pruneCategoryTree,
} from '../../utils/CategoryUtils';
import CategoryFilter from '../../components/products/CategoryFilter';

export default function ProductOverview() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [featured, setFeatured] = useState(false);
  const [showLoyaltyProducts, setShowLoyaltyProducts] = useState(false);
  const navigate = useNavigate();
  const [showArchived, setShowArchived] = useState(false);
  const [categoriesTree, setCategoriesTree] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const textColor = useColorModeValue('navy.700', 'white');
  const archivedTextColor = useColorModeValue('gray.500', 'gray.400');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');
  const archivedBg = useColorModeValue('gray.50', 'gray.700');

  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const cancelRef = useRef();
  const toast = useToast();

  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure(); // Drawer state

  // Fetch Products and All Categories
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const productFilters = {
          is_archived: showArchived,
          sortBy: 'id',
          sortOrder: 'desc',
        };

        if (selectedCategoryFilter) {
          productFilters.category_uuid = selectedCategoryFilter;
        }

        // Fetch products based on filters
        const productsData = await getProducts(productFilters);
        setProducts(productsData);

        // **Debugging Logs**
        console.log('Fetched Products:', productsData);

        // **Fetch all categories without filtering**
        const categoriesData = await getCategories();

        // **Debugging Logs**
        console.log('Fetched Categories:', categoriesData);

        // **Ensure that categories have 'uuid'**
        const validCategories = categoriesData.filter(
          (category) => category.uuid,
        );
        if (validCategories.length !== categoriesData.length) {
          console.warn('Some categories are missing the "uuid" field.');
        }

        // Extract unique category UUIDs from products
        const productCategoryUuids = new Set(
          productsData.map((product) => product.category_uuid),
        );

        // Transform categories for tree select
        const transformedCategoriesTree = transformCategoriesForTreeSelect(
          validCategories,
          'en',
        );

        // Prune the category tree to include only categories with associated products
        const prunedCategoriesTree = pruneCategoryTree(
          transformedCategoriesTree,
          productCategoryUuids,
        );

        setCategoriesTree(transformedCategoriesTree);

        // Also set the flattened categories if needed elsewhere
        const flattened = flattenCategories(validCategories, 'en');
        setCategories(flattened);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [showArchived, selectedCategoryFilter]);

  const handleCategoryFilterChange = (selectedValue) => {
    setSelectedCategoryFilter(selectedValue);
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  };

  const handleFeaturedChange = (isFeatured) => {
    setFeatured(isFeatured);
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  };

  const handleShowArchivedChange = (isShowArchived) => {
    setShowArchived(isShowArchived);
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  };

  // Build a category map for easy lookup
  const categoryMap = useMemo(() => {
    const map = {};
    categories.forEach((category) => {
      map[category.uuid] = category;
    });
    return map;
  }, [categories]);

  // Fetch all categories for the DropdownTreeSelect (independent of products)
  const fetchCategories = useCallback(async () => {
    try {
      const data = await getCategories(); // Adjust the API endpoint if needed
      const transformed = transformCategoriesForTreeSelect(data, 'en');
      setCategoriesTree(transformed);
    } catch (error) {
      console.error('Failed to fetch categories:', error);
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const handleCategoryChange = (currentNode, selectedNodes) => {
    if (selectedNodes.length > 0) {
      setSelectedCategoryFilter(selectedNodes[0].value);
    } else {
      setSelectedCategoryFilter(null);
    }
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  };

  const filteredProducts = useMemo(() => {
    return products.filter((product) => {
      const passesCategoryFilter = selectedCategoryFilter
        ? product.category_uuid === selectedCategoryFilter
        : true;

      const passesLoyaltyFilter = showLoyaltyProducts
        ? true
        : product.loyalty_program_uuid === null;

      const passesFeaturedFilter = featured ? product.is_featured : true;

      return (
        passesCategoryFilter && passesLoyaltyFilter && passesFeaturedFilter
      );
    });
  }, [products, featured, showLoyaltyProducts, selectedCategoryFilter]);

  const hasAnyImages = useMemo(() => {
    return filteredProducts.some((product) => {
      if (!product.images) return false;
      if (!Array.isArray(product.images)) return false;
      if (product.images.length === 0) return false;
      return product.images.some(
        (img) => img && img.thumb_url && img.thumb_url.trim() !== '',
      );
    });
  }, [filteredProducts]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          ID
        </Text>
      ),
      cell: (info) => {
        const isArchived = info.row.original.is_archived;
        return (
          <Text
            color={isArchived ? archivedTextColor : textColor}
            fontSize="md"
            fontWeight="500"
            textDecoration={isArchived ? 'line-through' : 'none'}
          >
            {info.getValue()}
          </Text>
        );
      },
      enableSorting: true,
    }),
    ...(hasAnyImages
      ? [
          columnHelper.accessor('images', {
            id: 'image',
            header: () => (
              <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
                IMAGE
              </Text>
            ),
            cell: (info) => {
              const images = info.getValue();
              if (!images || !Array.isArray(images) || images.length === 0) {
                return null;
              }

              const thumbUrl =
                images.find((img) => img.is_featured)?.thumb_url ||
                images[0]?.thumb_url;

              return thumbUrl ? (
                <Flex align="center" justify="center">
                  <Image
                    src={thumbUrl}
                    alt="Product image"
                    boxSize="50px"
                    objectFit="cover"
                    borderRadius="md"
                  />
                </Flex>
              ) : null;
            },
          }),
        ]
      : []),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          NAME
        </Text>
      ),
      cell: (info) => {
        const isArchived = info.row.original.is_archived;
        return (
          <Flex direction="column">
            <Text
              color={isArchived ? archivedTextColor : textColor}
              fontSize="md"
              fontWeight="500"
              textDecoration={isArchived ? 'line-through' : 'none'}
            >
              {info.getValue()}
            </Text>
            {isArchived && (
              <Badge colorScheme="gray" mt="1">
                Archived
              </Badge>
            )}
          </Flex>
        );
      },
    }),
    columnHelper.accessor('category_uuid', {
      id: 'category',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          CATEGORY
        </Text>
      ),
      cell: (info) => {
        const isArchived = info.row.original.is_archived;
        const categoryUuid = info.getValue();
        const category = categoryMap[categoryUuid];

        const categoryName =
          category?.translations?.en?.category_name || 'Uncategorized';

        const handleCategoryClick = () => {
          if (selectedCategoryFilter === categoryUuid) {
            setSelectedCategoryFilter(null);
          } else {
            setSelectedCategoryFilter(categoryUuid);
          }
          setPagination((prev) => ({ ...prev, pageIndex: 0 }));
        };

        return (
          <Tooltip
            label={`Category: ${categoryName}`}
            aria-label="Category Tooltip"
            bg="gray.600"
            borderRadius="md"
          >
            <Badge
              colorScheme={category ? 'blue' : 'gray'}
              cursor="pointer"
              onClick={handleCategoryClick}
              _hover={{ opacity: 0.8 }}
            >
              {categoryName}
            </Badge>
          </Tooltip>
        );
      },
      enableSorting: true,
    }),

    columnHelper.accessor('price', {
      id: 'price',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          PRICE
        </Text>
      ),
      cell: (info) => {
        const isArchived = info.row.original.is_archived;
        return (
          <Text
            color={isArchived ? archivedTextColor : textColor}
            fontSize="md"
            fontWeight="500"
            textDecoration={isArchived ? 'line-through' : 'none'}
          >
            €{info.getValue()}
          </Text>
        );
      },
    }),
    columnHelper.accessor('is_featured', {
      id: 'is_featured',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          FEATURED
        </Text>
      ),
      cell: (info) => {
        const isArchived = info.row.original.is_archived;
        return (
          <Badge
            colorScheme={
              isArchived ? 'gray' : info.getValue() ? 'green' : 'gray'
            }
          >
            {info.getValue() ? 'Yes' : 'No'}
          </Badge>
        );
      },
    }),
    columnHelper.display({
      id: 'stock',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          STOCK
        </Text>
      ),
      cell: (info) => {
        const { quantity, sold_quantity } = info.row.original;
        const stock = Math.max((quantity || 0) - (sold_quantity || 0), 0);
        const isArchived = info.row.original.is_archived;
        return (
          <Text
            color={isArchived ? archivedTextColor : textColor}
            fontSize="md"
            fontWeight="500"
            textDecoration={isArchived ? 'line-through' : 'none'}
          >
            {stock}
          </Text>
        );
      },
    }),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          ACTIONS
        </Text>
      ),
      cell: (info) => {
        const isArchived = info.row.original.is_archived;
        return (
          <Flex align="center">
            <HStack spacing="4">
              <Icon
                w="16px"
                h="16px"
                as={MdEdit}
                cursor={isArchived ? 'not-allowed' : 'pointer'}
                color={isArchived ? 'gray.400' : brandColor}
                onClick={() => {
                  if (!isArchived) {
                    navigate(`/admin/products/edit/${info.row.original.uuid}`);
                  }
                }}
                pointerEvents={isArchived ? 'none' : 'auto'}
              />
              <Icon
                w="16px"
                h="16px"
                as={isArchived ? RiInboxUnarchiveFill : RiArchiveFill}
                cursor="pointer"
                color={isArchived ? brandColor : 'red.500'}
                onClick={() => {
                  const productId = info.row.original.uuid;
                  if (productId) {
                    setSelectedProductId(productId);
                    setIsArchiveDialogOpen(true);
                  } else {
                    console.error('productId is undefined');
                  }
                }}
              />
            </HStack>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          CREATED AT
        </Text>
      ),
      cell: (info) => {
        const isArchived = info.row.original.is_archived;
        const date = new Date(info.getValue());
        const formattedDate = date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });
        return (
          <Text
            color={isArchived ? archivedTextColor : textColor}
            fontSize="md"
            fontWeight="500"
            textDecoration={isArchived ? 'line-through' : 'none'}
          >
            {formattedDate}
          </Text>
        );
      },
      enableSorting: true,
    }),
  ];

  const table = useReactTable({
    data: filteredProducts,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      sorting: [{ id: 'id', desc: true }],
    },
  });

  const handleArchive = async () => {
    try {
      const selectedProduct = products.find(
        (product) => product.uuid === selectedProductId,
      );
      if (!selectedProduct) {
        throw new Error('Selected product not found');
      }
      const newStatus = !selectedProduct.is_archived;

      await updateProduct(selectedProductId, {
        is_archived: newStatus,
      });

      toast({
        title: `Product ${newStatus ? 'archived' : 'unarchived'}`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      let updatedProducts = products.map((product) =>
        product.uuid === selectedProductId
          ? { ...product, is_archived: newStatus }
          : product,
      );

      if (newStatus && !showArchived) {
        updatedProducts = updatedProducts.filter(
          (product) => product.uuid !== selectedProductId,
        );
      } else if (!newStatus && showArchived) {
        updatedProducts = updatedProducts.filter(
          (product) => product.uuid !== selectedProductId,
        );
      }

      setProducts(updatedProducts);
    } catch (error) {
      console.error('Error:', error);
      const selectedProduct = products.find(
        (product) => product.uuid === selectedProductId,
      );
      const newStatus = selectedProduct ? !selectedProduct.is_archived : false;
      toast({
        title: `Error ${newStatus ? 'archiving' : 'unarchiving'} product`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsArchiveDialogOpen(false);
    }
  };

  useEffect(() => {
    const totalPages = table.getPageCount();
    if (pagination.pageIndex >= totalPages && totalPages > 0) {
      setPagination((prev) => ({
        ...prev,
        pageIndex: totalPages - 1,
      }));
    }
  }, [table.getPageCount(), pagination.pageIndex, setPagination]);

  return (
    <Flex direction="column" mt={{ sm: '150px', md: '125px', lg: '75px' }}>
      <Card
        w="100%"
        px={{ base: '16px', md: '24px' }}
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
        minHeight={{ sm: 'auto', lg: 'calc(100vh - 200px)' }}
      >
        {/* Top Filter Controls */}
        <Flex
          align={{ sm: 'center', lg: 'center' }}
          justify={{ sm: 'center', lg: 'space-between' }}
          mb="36px"
          mt="24px"
        >
          <Flex align="center"></Flex>

          {/* Action Buttons */}
          <Flex align="center" flexWrap="wrap">
            <IconButton
              aria-label="Filter Products"
              icon={<FiFilter />}
              colorScheme="blue"
              variant="outline"
              onClick={onOpen}
              mr={{ base: 0, md: 2 }}
              mb={{ base: 2, md: 0 }}
            />
            <Button
              leftIcon={<AddIcon />}
              colorScheme="blue"
              variant="solid"
              onClick={() => navigate('/admin/products/new-product')}
              mr={{ base: 0, md: 2 }}
              mb={{ base: 2, md: 0 }}
              width={{ base: '100%', md: 'auto' }}
            >
              Add Product
            </Button>
          </Flex>
        </Flex>

        {/* Main Content with CategoryFilter Sidebar */}
        <Flex direction={{ base: 'column', lg: 'row' }}>
          {/* Products Table */}
          <Box flex="1">
            {loading ? (
              <Flex justify="center" align="center" h="200px">
                <Spinner size="lg" />
              </Flex>
            ) : filteredProducts.length > 0 ? (
              <>
                <Table variant="simple" color="gray.500" mb="24px">
                  <Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <Tr
                        key={headerGroup.id}
                        bg={
                          headerGroup.headers.some(
                            (header) => header.column.id === 'actions',
                          )
                            ? 'transparent'
                            : 'transparent'
                        }
                      >
                        {headerGroup.headers.map((header) => (
                          <Th
                            key={header.id}
                            borderColor={borderColor}
                            cursor={
                              header.column.getCanSort() ? 'pointer' : 'default'
                            }
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            <Flex align="center" justify="space-between">
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                              {header.column.getIsSorted() ? (
                                header.column.getIsSorted() === 'desc' ? (
                                  <Text fontSize="xs">↓</Text>
                                ) : (
                                  <Text fontSize="xs">↑</Text>
                                )
                              ) : (
                                header.column.getCanSort() && (
                                  <Text fontSize="xs">⇅</Text>
                                )
                              )}
                            </Flex>
                          </Th>
                        ))}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody>
                    {table.getRowModel().rows.map((row) => {
                      const isArchived = row.original.is_archived;
                      return (
                        <Tr
                          key={row.id}
                          bg={isArchived ? archivedBg : 'transparent'}
                        >
                          {row.getVisibleCells().map((cell) => (
                            <Td
                              key={cell.id}
                              borderColor={borderColor}
                              align="center"
                              whiteSpace="nowrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </Td>
                          ))}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>

                {filteredProducts.length > 10 && (
                  <Flex
                    w="100%"
                    justify="space-between"
                    px="20px"
                    pt="10px"
                    pb="5px"
                    alignItems="center"
                  >
                    <Text fontSize="sm" color="gray.500" fontWeight="normal">
                      Showing{' '}
                      {filteredProducts.length === 0
                        ? 0
                        : pagination.pageIndex * pagination.pageSize + 1}{' '}
                      to{' '}
                      {Math.min(
                        (pagination.pageIndex + 1) * pagination.pageSize,
                        filteredProducts.length,
                      )}{' '}
                      of {filteredProducts.length} entries
                    </Text>

                    <Pagination
                      currentPage={pagination.pageIndex}
                      pageCount={table.getPageCount()}
                      onChange={(newPage) =>
                        setPagination((prev) => ({
                          ...prev,
                          pageIndex: newPage,
                        }))
                      }
                      textColor={textColor}
                      borderColor={borderColor}
                      brandColor={brandColor}
                    />
                  </Flex>
                )}
              </>
            ) : (
              <Text textAlign="center" mt="20px">
                No products found.
              </Text>
            )}
          </Box>
        </Flex>
      </Card>

      {/* Filter Drawer */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">Filter Products</DrawerHeader>
          <DrawerBody>
            <CategoryFilter
              categories={categoriesTree}
              selectedCategoryUuid={selectedCategoryFilter}
              onFilterChange={handleCategoryFilterChange}
              featured={featured}
              setFeatured={handleFeaturedChange}
              showArchived={showArchived}
              setShowArchived={handleShowArchivedChange}
            />

            <Flex mt={6} justify="space-between">
              <Button
                variant="ghost"
                onClick={() => {
                  setSelectedCategoryFilter(null);
                  setFeatured(false);
                  setShowArchived(false);
                }}
              >
                Clear Filters
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => {
                  onClose();
                }}
              >
                Apply Filters
              </Button>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Archive Confirmation Dialog */}
      <ProductArchiveAlert
        isOpen={isArchiveDialogOpen}
        onClose={() => setIsArchiveDialogOpen(false)}
        onConfirm={handleArchive}
        cancelRef={cancelRef}
        isArchived={
          products.find((p) => p.uuid === selectedProductId)?.is_archived
        }
      />
    </Flex>
  );
}
